import { ReactElement, useMemo } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { Stack, Box } from '@mui/material'
import { ErrorFieldBase, Errors, Form } from '@ui/forms'
import SubmitButton from '@ui/forms/SubmitButton'
import { crossSectionFormSchema } from '../../schema'
import { FormFields } from '../FormFields'
import { getDefaultValues } from './schema'

interface Props {
  selectedElement: string
  defaultCS?: CrossSection
  elementType: ElementTypes
  mutateAsync: UseMutateAsyncFunction<unknown, unknown, CrossSection, unknown>
  isLoading: boolean
  isDisabled?: boolean
  warning?: string
  disableIfDirty?: boolean
}

const SingleCSForm = ({
  elementType,
  mutateAsync,
  isLoading,
  defaultCS,
  isDisabled = false,
  warning,
  disableIfDirty = true,
  selectedElement,
}: Props): ReactElement => {
  const handleSave = ({
    materialType,
    shape,
    steelShape,
    material,
    usage_class,
  }: {
    materialType: string
    shape: CrossSectionShape
    steelShape: CrossSectionShape
    material: Material
    usage_class: UsageClass
  }) => {
    // transform shapes into something which actually makes sense
    switch (materialType) {
      case 'softwoodMaterial': {
        const crossSection = { material, shape, usage_class } as CrossSection
        return mutateAsync(crossSection)
      }
      case 'glulamMaterial': {
        const crossSection = { material, shape, usage_class } as CrossSection
        return mutateAsync(crossSection)
      }
      case 'steelMaterial': {
        const crossSection = { shape: steelShape, material } as CrossSection
        return mutateAsync(crossSection)
      }
      default: {
        throw new Error('Be loud because this is really unexpected')
      }
    }
  }

  const defaultValues = useMemo(
    () => getDefaultValues(defaultCS),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultCS, selectedElement],
  )

  return (
    <>
      <Form
        onSubmit={data => handleSave(data.crossSection)}
        defaultValues={defaultValues}
        validationSchema={crossSectionFormSchema}
        data-cy="crosssection-form"
        enableReinitialize
      >
        <Stack p={1} border={1} borderColor="grey.200" borderRadius={1} spacing={2}>
          <FormFields elementType={elementType} isDisabled={isDisabled} />

          {warning && (
            <Box mt={1}>
              <ErrorFieldBase severity="warning" message={warning} />
            </Box>
          )}

          <Errors />

          <Stack direction="row" justifyContent="end" spacing={1}>
            <SubmitButton loading={isLoading} disableIfNotDirty={disableIfDirty} />
          </Stack>
        </Stack>
      </Form>
    </>
  )
}

export default SingleCSForm
