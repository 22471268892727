import { useCallback, useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { mapValueKey, naturalSortComparator } from '@editorUtils'
import { useSlabBeamToAssembly } from '@hooks'
import { MAX_UTILIZATION_WARNING_THRESHOLD } from '@resultsConfig'
import { useResultsQueryParams } from '@resultsHooks'
import { makeCrossSectionLabel, makeSlabBeamCrossSectionLabel, getElementType } from '@resultsUtils'
import { AxiosError } from 'axios'
import produce from 'immer'
import { filter, find, findIndex, isEqual, maxBy, reject, remove } from 'lodash-es'
import { closeSnackbar, useSnackbar } from 'notistack'
import {
  ArrowForward,
  Check,
  Close,
  Edit,
  Warning,
  EditNote,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@mui/icons-material'
import { Tooltip, IconButton, Stack, Popover, Typography, Button, Checkbox } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
  GridRowId,
  useGridApiRef,
  gridExpandedSortedRowIdsSelector,
  GridRowParams,
} from '@mui/x-data-grid-pro'
import { UtilizationC90TextIcon, UtilizationTextIcon } from '@ui/icons/misc'
import { Box } from '@ui/structure'
import { useControlStore, useResultsStore, useSystemManagerStore } from '@editorStores'
import { useElementLabel } from '@editorHooks'
import { SingleElementCSForm } from '@editorComponents'
import { getElementCrossSectionAssignment, getMemberCheckSettings } from '@queries'
import {
  savePositionGrouping,
  saveSingleMemberCheckSettings,
  updateElementCrossSectionAssignment,
} from '@mutations'
import { buildErrorMessage } from 'src/constants'
import {
  getSlabBeamCrossSectionFromAssembly,
  getSlabBeamStepSizeFromAssembly,
} from 'src/utils/assemblies'
import CompressionSettingsForm from './components/CompressionSettingsForm'
import CustomToolbar from './components/CustomToolbar'
import GroupDetailView from './components/GroupDetailView'
import ShearCheckCell from './components/ShearCheckCell'
import { EditMode } from './misc'

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '& .MuiDataGrid-cell': {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
  },
  '& .row-over-utilization': {
    color: theme.palette.error.main,
  },
  '& .MuiDataGrid-row.Mui-selected.row-over-utilization': {
    '& .MuiDataGrid-cell': {
      color: theme.palette.error.main,
    },
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
}))

interface Props {
  bundles: MemberPositionBundleWithMaxUtilization[]
  positionType: PositionGroupingType
  isDownloadingExportDocument: boolean
  onClickDownloadExportDocument: () => void
  showShearCheckColumn?: boolean
}

const PositionResultsGrid = ({
  bundles,
  positionType,
  isDownloadingExportDocument,
  onClickDownloadExportDocument,
  showShearCheckColumn = false,
}: Props): React.ReactElement => {
  const { projectId } = useParams()
  const {
    params: { selectedElements },
    actions: { setIsLocalMode, selectPosition, setSelectedCheckPosition },
  } = useResultsQueryParams()

  const queryClient = useQueryClient()

  const getLabel = useElementLabel()
  const elementType = getElementType(positionType)

  // useResultsStore
  const structuralChecks = useResultsStore(state => state.structuralChecks)
  const memberCheckSettings = useResultsStore(state => state.memberCheckSettings)
  const memberCheckSettingsFromLastComputation = useResultsStore(
    state => state.memberCheckSettingsFromLastComputation,
  )
  const elementCrossSectionAssignmentLastComputation = useResultsStore(
    state => state.crossSectionsFromLastComputation,
  )
  const setWallRipsPositionGrouping = useResultsStore(state => state.setWallRipsPositionGrouping)
  const setWallLintelsPositionGrouping = useResultsStore(
    state => state.setWallLintelsPositionGrouping,
  )
  const setSlabBeamsPositionGrouping = useResultsStore(state => state.setSlabBeamsPositionGrouping)
  const setRoofSlabBeamsPositionGrouping = useResultsStore(
    state => state.setRoofSlabBeamsPositionGrouping,
  )
  const setBeamsPositionGrouping = useResultsStore(state => state.setBeamsPositionGrouping)
  const setPurlinsPositionGrouping = useResultsStore(state => state.setPurlinsPositionGrouping)
  const setColumnsPositionGrouping = useResultsStore(state => state.setColumnsPositionGrouping)

  // useControlStore
  const isDrawerExpanded = useControlStore(state => state.isDrawerExpanded)

  // useSystemManagerStore
  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const slabBeamGuidToAssembly = useSlabBeamToAssembly()

  // useState
  const [intermediateBundles, setIntermediateBundles] = useState(bundles)
  const [elementsToGroup, setElementsToGroup] = useState<{
    aggregator: string | null
    aggregated: string[]
  }>({ aggregator: null, aggregated: [] })
  const [editMode, setEditMode] = useState<EditMode>(EditMode.None)
  const [representativesOpen, setRepresentativesOpen] = useState(false)

  // useMemo
  const elementCrossSectionAssignmentToGuid = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )
  const supportCompressionChecks = useMemo(() => {
    return (
      structuralChecks?.filter(
        check =>
          check.check_type === 'SupportCompression' ||
          check.check_type === 'SteelSupportCompression',
      ) || []
    )
  }, [structuralChecks])

  const selectedElement = useMemo(
    () => (selectedElements?.length === 1 ? selectedElements?.[0] : null),
    [selectedElements],
  )

  const crossSectionsFromLastComputationLookup = useMemo(
    () => mapValueKey(elementCrossSectionAssignmentLastComputation, 'element_guid'),
    [elementCrossSectionAssignmentLastComputation],
  )
  const checkSettingsFromLastComputationLookup = useMemo(
    () => mapValueKey(memberCheckSettingsFromLastComputation, 'element_guid'),
    [memberCheckSettingsFromLastComputation],
  )
  const checkSettingsLookup = useMemo(
    () => mapValueKey(memberCheckSettings, 'element_guid'),
    [memberCheckSettings],
  )

  const isSlabBeam = ['slab_beams', 'roof_slab_beams'].includes(elementType)
  const csIsEditable = !isSlabBeam
  const setSingleMemberCheckSetting = useResultsStore(state => state.setSingleMemberCheckSetting)
  const setSingleCrossSection = useSystemManagerStore(state => state.setSingleCrossSection)

  // onclick callbacks

  // Add this function to handle export changes
  const changeExportedPosition = (position: string, exported: boolean) => {
    const newBundles = intermediateBundles.map(bundle =>
      bundle.representative_position === position ? { ...bundle, exported } : bundle,
    )
    setIntermediateBundles(newBundles)
  }

  const { enqueueSnackbar } = useSnackbar()
  const elementGroupingSnackbarKey = 'snk-group-elements'

  /* Save Button */
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: MemberPositionBundle[]) => {
      return {
        data: await savePositionGrouping.request(projectId as string, positionType, data),
        positionType,
      }
    },
    onSuccess: ({ data, positionType }) => {
      switch (positionType) {
        case 'wall-lintels':
          setWallLintelsPositionGrouping(data)
          break
        case 'wall-rips':
          setWallRipsPositionGrouping(data)
          break
        case 'slab-beams':
          setSlabBeamsPositionGrouping(data)
          break
        case 'roof-slab-beams':
          setRoofSlabBeamsPositionGrouping(data)
          break
        case 'beams':
          setBeamsPositionGrouping(data)
          break
        case 'purlins':
          setPurlinsPositionGrouping(data)
          break
        case 'columns':
          setColumnsPositionGrouping(data)
          break
      }
      enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Änderungen'), {
        variant: 'error',
      })
    },
  })

  const handleSave = () => {
    if (editMode === EditMode.Grouping && elementsToGroup.aggregator) {
      const newBundles = getIntermediateBundlesWithUpdatedGroupings(
        elementsToGroup.aggregator,
        elementsToGroup.aggregated,
      )
      mutate(newBundles)
      selectPosition(elementsToGroup.aggregator)
      handleGroupingModeEnd()
    } else if (editMode === EditMode.None) {
      mutate(intermediateBundles)
    }
  }

  const { mutateAsync: saveMemberSettings, isLoading: isLoadingMemberSettings } = useMutation(
    (data: SettingsOnMember) => saveSingleMemberCheckSettings.request(projectId as string, data),
    {
      onMutate: async (data: SettingsOnMember) => {
        setSingleMemberCheckSetting(data)
      },
      onError: () => {
        enqueueSnackbar(
          'Einstellungen oder Querschnitts-Zuweisung konnten nicht gespeichert werden',
          {
            variant: 'error',
          },
        )
        queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
      },
    },
  )
  const { mutateAsync: saveCSAssignment, isLoading: isLoadingCSAssignment } = useMutation(
    (data: ElementCSAssignment) => {
      return updateElementCrossSectionAssignment.request(projectId as string, data)
    },
    {
      onMutate: async (data: ElementCSAssignment) => {
        // Update the local state directly so no refetch is required
        setSingleCrossSection(data.element_guid, data.element_cs)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
        // Refetch onError to ensure local state is in sync with backend again
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
      },
    },
  )

  const onClickUngroupMember = useCallback(
    (removedElement: string) => {
      const clickedBundleIdx = findIndex(intermediateBundles, bundle =>
        bundle.representative_for.includes(removedElement),
      )
      if (clickedBundleIdx !== -1) {
        const clickedBundle = intermediateBundles[clickedBundleIdx]
        const currentBundleIsExported = clickedBundle.exported as boolean
        const currentAggregated = clickedBundle.representative_for
        const newAggregated = reject(currentAggregated, el => el === removedElement)
        const newClickedBundle = {
          ...clickedBundle,
          representative_for: newAggregated,
        }
        const newStandaloneBundleForUngroupedElement: MemberPositionBundle = {
          representative_position: removedElement,
          representative_for: [],
          exported: currentBundleIsExported,
        }
        // build new intermediate bundles from a copy of current state
        let newIntermediateBundles = [...intermediateBundles]
        newIntermediateBundles[clickedBundleIdx] = newClickedBundle
        newIntermediateBundles = [...newIntermediateBundles, newStandaloneBundleForUngroupedElement]
        setIntermediateBundles(newIntermediateBundles)
        // if the new bundle is empty, close the group detail view again
        newAggregated.length === 0 && setRepresentativesOpen(false)
        // not sure if edit mode would have side effects, for that reason let's just not save it in that case
        editMode !== EditMode.Grouping && mutate(newIntermediateBundles)
      }
    },
    [editMode, intermediateBundles, mutate],
  )

  /* Update comments */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRowUpdate = (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
    const updatedBundles = intermediateBundles.map(bundle =>
      bundle.representative_position === newRow.id
        ? { ...bundle, comment: newRow.comment, extended_comment: newRow.extended_comment }
        : bundle,
    )
    setIntermediateBundles(updatedBundles)
    return newRow
  }

  /* AP configuration */
  const [anchorElementForPopover, setAnchorElementForPopover] = useState<{
    anchor: HTMLButtonElement
    guid: string
    source: 'compression-settings'
  } | null>(null)

  const handleClickForSettingsForm = (event: React.MouseEvent<HTMLButtonElement>, guid: string) => {
    setAnchorElementForPopover({
      anchor: event.currentTarget,
      guid,
      source: 'compression-settings',
    })
  }
  const handleCloseForPopover = () => {
    setSelectedCheckPosition(undefined)
    setAnchorElementForPopover(null)
  }

  const getIntermediateBundlesWithUpdatedGroupings = (
    aggregator: string,
    aggregated: string[],
  ): MemberPositionBundleWithMaxUtilization[] => {
    const newBundles = produce(state => {
      aggregated.map(aggregatedGuid => remove(state, ['representative_position', aggregatedGuid]))

      const index = findIndex(state, { representative_position: aggregator })
      state[index].representative_for = aggregated
      state[index].exported = true
    }, intermediateBundles)()
    return newBundles
  }

  useEffect(() => {
    setIntermediateBundles(bundles)
  }, [bundles])

  const rows = useMemo(
    () =>
      intermediateBundles.map(bundle => {
        let crossSection: CrossSection
        let crossSectionLabel
        if (isSlabBeam) {
          const assembly = slabBeamGuidToAssembly[bundle.representative_position]
          const stepSize = getSlabBeamStepSizeFromAssembly(assembly)
          crossSection = getSlabBeamCrossSectionFromAssembly(assembly)
          crossSectionLabel = makeSlabBeamCrossSectionLabel(crossSection, stepSize)
        } else {
          crossSection =
            elementCrossSectionAssignmentToGuid[bundle.representative_position]?.element_cs
          crossSectionLabel = crossSection && makeCrossSectionLabel(crossSection)
        }

        const aggregatedElementBundles: AggregatedElementDataSummary[] =
          bundle.representative_for.map(elementGuid => {
            const elementPositionChecks = filter(structuralChecks, { element_guid: elementGuid })
            const maxCheckUtilisation = maxBy(elementPositionChecks, check => check.max_utilization)

            let aggregatedCrossSectionLabel
            if (isSlabBeam) {
              const assembly = slabBeamGuidToAssembly[elementGuid]
              const crossSection = getSlabBeamCrossSectionFromAssembly(assembly)
              const stepSize = getSlabBeamStepSizeFromAssembly(assembly)
              aggregatedCrossSectionLabel = makeSlabBeamCrossSectionLabel(crossSection, stepSize)
            } else {
              const crossSection = elementCrossSectionAssignmentToGuid[elementGuid]?.element_cs
              aggregatedCrossSectionLabel = crossSection && makeCrossSectionLabel(crossSection)
            }
            return {
              guid: elementGuid,
              label: getLabel(elementGuid),
              crossSectionLabel: aggregatedCrossSectionLabel || 'n/a',
              maxUtilisation: maxCheckUtilisation?.max_utilization,
            }
          })

        const overutilisedAggregatedElements = aggregatedElementBundles.filter(bundle => {
          return (
            bundle.maxUtilisation !== undefined &&
            bundle.maxUtilisation > MAX_UTILIZATION_WARNING_THRESHOLD
          )
        })

        let isChanged
        if (!isSlabBeam) {
          const element_guid = bundle.representative_position
          const crossSectionFromComputation = crossSectionsFromLastComputationLookup[element_guid]
          const currentCrossSection = elementCrossSectionAssignmentToGuid[element_guid]
          const checkSettingsFromLastComputation =
            checkSettingsFromLastComputationLookup[element_guid]
          const currentCheckSettings = checkSettingsLookup[element_guid]
          // there needs to be a check if the elements not null, otherwise everything is changed
          isChanged =
            (crossSectionFromComputation &&
              currentCrossSection &&
              !isEqual(crossSectionFromComputation, currentCrossSection)) ||
            (checkSettingsFromLastComputation &&
              currentCheckSettings &&
              !isEqual(checkSettingsFromLastComputation, currentCheckSettings))
        } else {
          isChanged = false
        }

        return {
          id: bundle.representative_position,
          ...bundle,
          label: getLabel(bundle.representative_position),
          crossSection: crossSection,
          crossSectionLabel: crossSectionLabel,
          aggregatedElementBundles,
          overutilisedAggregatedElements,
          isChanged: isChanged,
        } as PositionResultsGridRow
      }),
    [
      checkSettingsFromLastComputationLookup,
      checkSettingsLookup,
      crossSectionsFromLastComputationLookup,
      elementCrossSectionAssignmentToGuid,
      getLabel,
      intermediateBundles,
      isSlabBeam,
      slabBeamGuidToAssembly,
      structuralChecks,
    ],
  )

  /* CS FORM */
  const [anchorElementForCSForm, setAnchorElementForCSForm] = useState<{
    anchor: HTMLButtonElement
    guid: string
  } | null>(null)

  const handleClickForCSForm = (event: React.MouseEvent<HTMLButtonElement>, guid: string) => {
    setAnchorElementForCSForm({
      anchor: event.currentTarget,
      guid,
    })
  }

  const handleCloseForCSForm = () => {
    setAnchorElementForCSForm(null)
  }

  const openRow = useMemo(() => {
    if (!selectedElement) return

    const selectedRepresentative = find(rows, row => row.id === selectedElement)

    if (selectedRepresentative && selectedRepresentative.representative_for.length > 0)
      return selectedRepresentative

    const selectedRow = rows.find(row => row.representative_for.includes(selectedElement))

    return selectedRow
  }, [rows, selectedElement])

  // Open grouped table if selectedElement is grouped
  useEffect(() => {
    if (!selectedElement) return

    const selectedRow = rows.find(row => row.representative_for.includes(selectedElement))

    if (selectedRow) setRepresentativesOpen(true)
  }, [rows, selectedElement])

  /* START Cell Render handlers */
  const renderCellRepresentative = (params: GridRenderCellParams) => (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation()
        if (editMode === EditMode.None) {
          handleGroupingModeStart(params.row.representative_position, params.row.representative_for)
        }
      }}
      style={{ cursor: 'pointer', height: '100%' }}
    >
      {params.row.overutilisedAggregatedElements.length > 0 && (
        <Tooltip
          title={`Overutilised in group: ${params.row.overutilisedAggregatedElements
            .map((bundle: { guid: string }) => getLabel(bundle.guid))
            .join(', ')}`}
        >
          <Warning />
        </Tooltip>
      )}
      <Tooltip
        title={params.row.representative_for.map((guid: string) => getLabel(guid)).join(', ')}
      >
        <Typography>{params.row.representative_for.length}</Typography>
      </Tooltip>
      {params.row.representative_for.length > 0 && (
        <IconButton
          size="small"
          onClick={(event: { stopPropagation: () => void }) => {
            event.stopPropagation()

            if (params.row.id === openRow?.id) setRepresentativesOpen(!representativesOpen)
            else {
              selectPosition(params.row.representative_position)
              setRepresentativesOpen(true)
            }
          }}
          sx={{ padding: 0 }}
        >
          <>
            {representativesOpen && params.row.id === openRow?.id ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )}
          </>
        </IconButton>
      )}
    </Stack>
  )

  const renderCellShearCheck = useCallback(
    (params: GridRenderCellParams<PositionResultsGridRow>) => {
      const memberGuid = params.row.representative_position
      const settings = find(memberCheckSettings, setting => setting.member_guid === memberGuid)

      if (settings?.setting_type === 'timber') {
        const shearSettings = settings.shear_check_settings

        return (
          <ShearCheckCell
            memberGuid={memberGuid}
            reducePointLoadsCloseToSupports={shearSettings.reduce_point_loads_close_to_supports}
            reduceShearForce={shearSettings.reduce_shear_force}
          />
        )
      } else return <></>
    },
    [memberCheckSettings],
  )

  const renderCellExported = (params: GridRenderCellParams) => (
    <IconButton
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        changeExportedPosition(params.row.representative_position, !params.row.exported)
      }}
      size="small"
      data-cy={
        params.row.exported
          ? `position-result-grid-export-checked-icon-${params.row.label}`
          : `position-result-grid-export-unchecked-icon-${params.row.label}`
      }
    >
      {params.row.exported ? (
        <Check color="success" fontSize="small" />
      ) : (
        <Close color="disabled" fontSize="small" />
      )}
    </IconButton>
  )
  /* END Cell Render handlers */

  /* START constant column widths */
  const columnWidths = {
    checkbox: 25,
    grouping: 50,
    label: 75,
    maxUtilWithoutSupportCompression: 50,
    maxUtilOnlySupportCompression: 50,
    crossSectionLabel: 150,
    apEdit: 40,
    representative: 80,
    exported: 40,
    comment: 100,
    extendedComment: 50,
    goToLocal: 40,
    shearCheck: 60,
  }
  /* END constant column widths */

  /* Note: the columns need display: flex to be able to align the content in the cells.
   * This is a known issue with MUI Grid: https://stackoverflow.com/questions/78347666/mui-x-data-grid-doesnt-respect-vertical-cell-alignment-after-update-to-7-2-0
   */
  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: '',
      headerAlign: 'center',
      width: 30,
      minWidth: 30,
      align: 'left',
      valueGetter: (value, row) => (row.isChanged ? 'C' : ''),
      renderCell: (params: GridRenderCellParams) =>
        params.row.isChanged && (
          <Tooltip title="Querschnitte oder Nachweiseinstellungen wurden seit der letzten Berechnung der Nachweise oder Ergebnisse angepasst. Diese Anzeige geht beim Neuladen der Seite oder Neuberechnen verloren.">
            <Typography
              sx={{ fontWeight: 'inherit' }}
              data-cy={`position-result-grid-element-${params.row.label}`}
            >
              {/* reuse value getter here */}
              {params.value}
            </Typography>
          </Tooltip>
        ),
      sortable: true,
      filterable: true,
      disableColumnMenu: true,
      display: 'flex',
    },
    {
      field: 'checkbox',
      headerName: '',
      width: columnWidths.checkbox,
      minWidth: columnWidths.checkbox,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        const { id, representative_for } = params.row
        return (
          <Checkbox
            size="small"
            disabled={
              elementsToGroup.aggregator === id ||
              representative_for.length > 0 ||
              elementsToGroup.aggregator === null
            }
            checked={[elementsToGroup.aggregator, ...elementsToGroup.aggregated].includes(id)}
            sx={{
              padding: 0,
            }}
            data-cy={`position-result-grid-checkbox-${params.row.label}`}
            onChange={() => {
              if (elementsToGroup.aggregated.includes(id)) {
                const newElements = elementsToGroup.aggregated.filter(
                  elementGuid => elementGuid !== id,
                )
                setElementsToGroup({
                  aggregator: elementsToGroup.aggregator,
                  aggregated: newElements,
                })
              } else {
                setElementsToGroup({
                  aggregator: elementsToGroup.aggregator,
                  aggregated: [...elementsToGroup.aggregated, id],
                })
              }
            }}
          />
        )
      },
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      display: 'flex',
    },
    {
      field: 'label',
      headerName: 'Bauteil',
      width: columnWidths.label,
      renderHeader: () => <Typography>{'Bauteil'}</Typography>,
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          sx={{ fontWeight: 'inherit' }}
          data-cy={`position-result-grid-element-${params.row.label}`}
        >
          {params.row.label}
        </Typography>
      ),
      disableColumnMenu: true,
      display: 'flex',
      sortComparator: naturalSortComparator,
    },
    {
      field: 'maxUtilWithoutSupportCompression',
      headerName: 'η',
      width: columnWidths.maxUtilWithoutSupportCompression,
      renderHeader: () => <UtilizationTextIcon />,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => {
        const isVibrationError =
          params.row.maxUtilWithoutSupportCompression?.check_type === 'Vibration' &&
          params.row.maxUtilWithoutSupportCompression?.max_utilization === 1.0

        return (
          <Tooltip title={params.row.maxUtilWithoutSupportCompression?.check_type || ''}>
            <Typography
              sx={{ fontWeight: 'inherit' }}
              data-cy={`position-result-grid-utilization-wo-support-compression-${params.row.label}`}
            >
              {isVibrationError
                ? 'ERR'
                : params.row.maxUtilWithoutSupportCompression?.max_utilization?.toFixed(2) ?? 'n/a'}
            </Typography>
          </Tooltip>
        )
      },
      valueGetter: (value, row) => row.maxUtilWithoutSupportCompression?.max_utilization ?? -1,
      type: 'number',
      disableColumnMenu: true,
      display: 'flex',
    },
    {
      field: 'maxUtilOnlySupportCompression',
      headerName: 'ηc,90',
      width: columnWidths.maxUtilOnlySupportCompression,
      renderHeader: () => <UtilizationC90TextIcon />,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.row.maxUtilOnlySupportCompression?.check_type || ''}>
          <Typography
            sx={{ fontWeight: 'inherit' }}
            data-cy={`position-result-grid-utilization-only-support-compression-${params.row.label}`}
          >
            {params.row.maxUtilOnlySupportCompression?.max_utilization?.toFixed(2) ?? 'n/a'}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (value, row) => row.maxUtilOnlySupportCompression?.max_utilization ?? -1,
      type: 'number',
      disableColumnMenu: true,
      display: 'flex',
    },
    {
      field: 'crossSectionLabel',
      headerName: 'QS',
      headerAlign: 'center',
      width: columnWidths.crossSectionLabel,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.value}
          {csIsEditable && (
            <IconButton
              size="small"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleClickForCSForm(e, params.row.representative_position)
              }
              data-cy={`btn-open-cs-form-${params.row.label}`}
            >
              <Edit fontSize="inherit" />
            </IconButton>
          )}
        </>
      ),
      align: 'center',
      disableColumnMenu: true,
      display: 'flex',
    },
    {
      field: 'ap-edit',
      headerName: 'AP',
      headerAlign: 'center',
      width: columnWidths.apEdit,
      minWidth: columnWidths.apEdit,
      renderCell: (params: GridRenderCellParams) => {
        const settingsForMember = memberCheckSettings?.find(
          settings => settings.element_guid === params.row.representative_position,
        )
        const compressionChecksForPosition = supportCompressionChecks.filter(
          check => check.element_guid === params.row.representative_position,
        ) as SupportCompressionStructuralCheck[] | SteelCompressionCheck[]

        return (
          <IconButton
            size="small"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              handleClickForSettingsForm(e, params.row.representative_position)
            }
            disabled={
              (settingsForMember?.setting_type !== 'timber' &&
                settingsForMember?.setting_type !== 'timber-slab') ||
              compressionChecksForPosition.length === 0
            }
            data-cy={`btn-open-settings-form-${params.row.label}`}
          >
            <Edit fontSize="inherit" />
          </IconButton>
        )
      },
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      display: 'flex',
    },
    {
      field: 'shear-check-settings',
      headerName: 'Schubeinstellungen',
      headerAlign: 'center',
      width: columnWidths.shearCheck,
      renderCell: renderCellShearCheck,
      disableColumnMenu: true,
      align: 'center',
      display: 'flex',
    },
    {
      field: 'representative',
      headerName: 'repr.',
      headerAlign: 'center',
      width: columnWidths.representative,
      renderCell: renderCellRepresentative,
      disableColumnMenu: true,
      valueGetter: (value, row) => row.representative_for.length,
      display: 'flex',
    },
    {
      field: 'exported',
      headerName: 'export',
      headerAlign: 'center',
      width: columnWidths.exported,
      minWidth: columnWidths.exported,
      renderCell: renderCellExported,
      align: 'center',
      disableColumnMenu: true,
      display: 'flex',
    },
    {
      field: 'comment',
      headerName: 'Kommentar',
      editable: true,
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Typography data-cy={`txt-edit-comment-${params.row.label}`}>
          {params.row.comment}
        </Typography>
      ),
      disableColumnMenu: true,
      display: 'flex',
    },
    {
      field: 'extended_comment',
      headerName: 'Vorbem.',
      width: columnWidths.extendedComment,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.value || ''}>
          <IconButton
            size="small"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              setIsLocalMode(true, params.row.representative_position, 'settings')
            }}
            sx={({ palette }) => ({
              color: params.value && params.value.length > 0 ? 'none' : `${palette.grey[400]}`, // Mimick 'disabled' colour
            })}
            data-cy={
              params.value && params.value.length > 0
                ? `icon-extended-comment-${params.row.label}`
                : `icon-extended-comment-${params.row.label}-disabled`
            }
          >
            <EditNote fontSize="inherit" />
          </IconButton>
        </Tooltip>
      ),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      display: 'flex',
    },
    {
      field: 'go-to-local',
      headerName: '',
      width: columnWidths.goToLocal,
      minWidth: columnWidths.goToLocal,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          size="small"
          onClick={(e: { stopPropagation: () => void }) => {
            e.stopPropagation()
            setIsLocalMode(true, params.row.id)
          }}
          data-cy={selectedElement === params.row.id ? 'btn-to-local-active' : undefined}
        >
          <ArrowForward fontSize="inherit" />
        </IconButton>
      ),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      display: 'flex',
    },
  ]

  /* START Grouping Mode functions */
  const handleGroupingModeStart = (aggregator: string, aggregated: string[]) => {
    if (elementsToGroup.aggregator !== null) return

    selectPosition(aggregator)
    setElementsToGroup({
      aggregator: aggregator,
      aggregated: aggregated,
    })
    setEditMode(EditMode.Grouping)
    enqueueSnackbar('Wähle die Bauteile aus der Tabelle, die gruppiert werden sollen.', {
      key: elementGroupingSnackbarKey,
      variant: 'info',
      persist: true,
      preventDuplicate: true,
      action: () => (
        <Button
          variant="outlined"
          size="small"
          color="inherit"
          onClick={() => {
            selectPosition(aggregator)
            handleGroupingModeEnd()
          }}
        >
          Abbrechen
        </Button>
      ),
    })
  }
  const handleGroupingModeEnd = () => {
    setElementsToGroup({
      aggregator: null,
      aggregated: [],
    })
    setEditMode(EditMode.None)
    closeSnackbar(elementGroupingSnackbarKey)
  }
  /* END Grouping Mode functions */

  const apiRef = useGridApiRef()

  const scrollToNewSelectionModel = useCallback(
    (newSelectionModel: GridRowId[]) => {
      // https://mui.com/x/react-data-grid/scrolling/
      if (!apiRef.current) return
      const selectedRowID = newSelectionModel[0] as string
      if (rows && selectedRowID) {
        // @ts-expect-error
        const selectedRowIndex = gridExpandedSortedRowIdsSelector(apiRef).findIndex(
          id => id === selectedRowID,
        )
        if (selectedRowIndex !== -1) {
          const currentPaginationModel = apiRef.current.state.pagination.paginationModel
          const pageIndex = Math.floor(selectedRowIndex / currentPaginationModel.pageSize)
          apiRef.current.setPage(pageIndex)
          apiRef.current.scrollToIndexes({
            rowIndex: selectedRowIndex,
            colIndex: 0,
          })
        }
      }
    },
    [apiRef, rows],
  )

  useEffect(() => {
    // this is required to make sure that the selected element is visible in the table
    if (selectedElement && apiRef.current) {
      // note: We want to achieve an ASYMMETRIC behaviour when the grouping mode is turned on.
      // when grouping mode is turned on,
      // When the user clicks an element in the scene, the element should be added to the group.
      // WWhen the user clicks on a row in the table, the element should NOT be added to the group.
      // in order for this to work, we need
      // 1) no rowSelectionModel defined on the Datagrid
      // 2) the row selection to be handled explicitly in the onRowClick
      // 3) use this effecto to check if the current row is already the selected element.
      //    If not check for edit mode and update the grouping.
      const newSelectionModel = [selectedElement] as string[]
      const currentSelectionModel = apiRef.current.getSelectedRows()
      const isCurrentlySelected = currentSelectionModel.has(selectedElement)
      if (!isCurrentlySelected) {
        apiRef.current.setRowSelectionModel([selectedElement] as string[])
        if (editMode === EditMode.Grouping) {
          if (selectedElement !== elementsToGroup.aggregator) {
            const newElementsToGroup = {
              aggregator: elementsToGroup.aggregator,
              aggregated: [...reject(elementsToGroup.aggregated, selectedElement), selectedElement],
            }
            setElementsToGroup(newElementsToGroup)
          }
        }
      }

      setTimeout(() => {
        scrollToNewSelectionModel(newSelectionModel)
      }, 0)
    }
  }, [
    selectedElement,
    rows,
    apiRef,
    editMode,
    elementsToGroup.aggregator,
    elementsToGroup.aggregated,
    scrollToNewSelectionModel,
  ])

  const positionFilters = useResultsStore(state => state.positionFilters)
  const setPositionFilter = useResultsStore(state => state.setPositionFilter)

  const positionSort = useResultsStore(state => state.positionSort)
  const setPositionSort = useResultsStore(state => state.setPositionSort)
  const positionPagination = useResultsStore(state => state.positionPagination)
  const setPositionPagination = useResultsStore(state => state.setPositionPagination)

  const checkSettings = useMemo(
    () =>
      memberCheckSettings?.find(
        settings => settings.element_guid === anchorElementForPopover?.guid,
      ) as TimberCheckSettings | TimberSlabCheckSettings,
    [anchorElementForPopover?.guid, memberCheckSettings],
  )

  const supportCompressionChecksOfPosition = useMemo(
    () =>
      supportCompressionChecks.filter(
        check => check.element_guid === anchorElementForPopover?.guid,
      ) as SupportCompressionStructuralCheck[] | SteelCompressionCheck[],
    [anchorElementForPopover?.guid, supportCompressionChecks],
  )

  return (
    <>
      <Stack spacing={2}>
        {representativesOpen && openRow && (
          <GroupDetailView
            openRow={openRow}
            renderCellRepresentative={renderCellRepresentative}
            renderCellExported={renderCellExported}
            saveCSAssignment={saveCSAssignment}
            onClickUngroupElement={onClickUngroupMember}
          />
        )}
        <StyledDataGrid
          // pagination
          // note:: datagrid community only allows 100 rows per page
          // so pagination is necessary in the free version
          pagination
          pageSizeOptions={[100]}
          rows={rows}
          filterModel={positionFilters[positionType]}
          onFilterModelChange={filter => setPositionFilter(positionType, filter)}
          sortModel={positionSort[positionType]}
          onSortModelChange={sort => setPositionSort(positionType, sort)}
          paginationModel={positionPagination[positionType]}
          onPaginationModelChange={pagination => setPositionPagination(positionType, pagination)}
          columns={columns}
          columnVisibilityModel={
            isDrawerExpanded
              ? {
                  'shear-check-settings': showShearCheckColumn,
                }
              : {
                  'shear-check-settings': showShearCheckColumn,
                  // Hide certain columns
                  status: false,
                  crossSectionLabel: false,
                  'ap-edit': false,
                  comment: false,
                  extended_comment: false,
                }
          }
          processRowUpdate={handleRowUpdate}
          density="compact"
          disableDensitySelector
          getRowClassName={params => {
            const maxUtilization = Math.max(
              params.row.maxUtilWithoutSupportCompression?.max_utilization || 0,
              params.row.maxUtilOnlySupportCompression?.max_utilization || 0,
            )
            const isVibrationError =
              params.row.maxUtilWithoutSupportCompression?.check_type === 'Vibration' &&
              params.row.maxUtilWithoutSupportCompression?.max_utilization === 1.0

            return maxUtilization > MAX_UTILIZATION_WARNING_THRESHOLD || isVibrationError
              ? 'row-over-utilization'
              : ''
          }}
          sx={{
            '& .MuiDataGrid-cell': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-main': {
              // Target the main container of the DataGrid
              maxHeight: '55vh', // Make sure the grid takes full height of its container
            },
          }}
          slots={{
            // @ts-expect-error
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              // @ts-expect-error
              onSave: handleSave,
              isLoading: isLoading,
              editMode: editMode,
              onCancelEdit: handleGroupingModeEnd,
              isDownloadingExportDocument: isDownloadingExportDocument,
              onClickDownloadExportDocument: onClickDownloadExportDocument,
              positionType: positionType,
            },
          }}
          checkboxSelection={false}
          disableRowSelectionOnClick={false}
          onRowClick={(params: GridRowParams) => {
            const newSelectedElement = params.id as string
            selectPosition(newSelectedElement)
          }}
          data-cy="position-result-grid"
          initialState={{
            sorting: {
              sortModel: [{ field: 'maxUtilWithoutSupportCompression', sort: 'desc' }],
            },
            pagination: {
              paginationModel: { pageSize: 100 },
            },
          }}
          // @ts-expect-error
          apiRef={apiRef}
        />
        <Popover
          open={!!anchorElementForPopover}
          anchorEl={anchorElementForPopover?.anchor}
          onClose={handleCloseForPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {anchorElementForPopover?.source === 'compression-settings' && checkSettings && (
            <Box padding={2}>
              <CompressionSettingsForm
                onSaveMemberCheckSettings={saveMemberSettings}
                onSaveCrossSectionAssignment={saveCSAssignment}
                handleClose={handleCloseForPopover}
                isLoading={isLoadingCSAssignment || isLoadingMemberSettings}
                elementType={elementType}
                supportCompressionChecks={supportCompressionChecksOfPosition}
                checkSettings={checkSettings}
              />
            </Box>
          )}
        </Popover>
        <Popover
          open={!!anchorElementForCSForm}
          anchorEl={anchorElementForCSForm?.anchor}
          onClose={handleCloseForCSForm}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {anchorElementForCSForm && (
            <Box padding={2} width="400px">
              <SingleElementCSForm
                selectedElement={anchorElementForCSForm.guid}
                elementType={elementType}
                invalidateResults={false}
                onSave={handleCloseForCSForm}
              />
            </Box>
          )}
        </Popover>
      </Stack>
    </>
  )
}

export default PositionResultsGrid
