import { ReactElement, ReactNode, useEffect } from 'react'
import produce from 'immer'
import { cloneDeep } from 'lodash-es'
import create from 'zustand'
import { combine } from 'zustand/middleware'
import { GridFilterModel, GridSortModel, GridPaginationModel } from '@mui/x-data-grid-pro'

type ResultElement = 'wallPositions' | 'slabPositions' | 'roofSlabPositions'

// TODO: get rid of rowIndex and columnIndex as soon as new AnchorDataGrid is used
type SelectedCell = { rowIndex: number; columnIndex: number; field: string }

export type CornerConnectionAdditionalLoadProposalStates =
  | { type: 'idle' }
  | ({ type: 'selected-anchor'; anchorGuid: string } & SelectedCell)
  | ({
      type: 'selected-anchor-and-wall'
      anchorGuid: string
      wallGuid: string
      biAnchorGuid?: string
      biWallGuid?: string
    } & SelectedCell)
  | ({
      type: 'proposal-created'
      anchorGuid: string
      wallGuid: string
      proposal: CornerConnectionProposal

      biAnchorGuid?: string
      biWallGuid?: string
      biProposal?: CornerConnectionProposal
    } & SelectedCell)
  | ({
      type: 'proposal-changed'
      anchorGuid: string
      wallGuid: string
      proposal: CornerConnectionProposal

      biAnchorGuid?: string
      biWallGuid?: string
      biProposal?: CornerConnectionProposal
    } & SelectedCell)

interface Props {
  children: ReactNode
}

interface ResultsInitialState {
  segmentForces: SegmentForces[]
  stiffeningSegmentChecks: StiffeningSegmentCheck[]
  anchorChecks: AnchorCheckOnSupport[] | null
  stiffeningIntervals: StiffeningSegment[]
  elementLoads: ElementLoad[] | null
  positionLoads: ElementLoad[] | null
  structuralChecks: CombinedPositionCheck[] | null
  standardRipChecks: StandardRipCheck[] | null
  loadTracingMap: LoadSource[] | null
  designForces: DesignSupportForcesOnPosition[] | null

  verticalTransmissionGraph: VerticalTransmissionGraph | null
  tensileTransmissionGraph: TensileTransmissionGraph | null

  anchorInterventionTableData: AnchorInterventionsTableRowData[] | null

  wallRipsPositionGrouping: MemberPositionBundle[] | null
  wallLintelsPositionGrouping: MemberPositionBundle[] | null
  slabBeamsPositionGrouping: MemberPositionBundle[] | null
  roofSlabBeamsPositionGrouping: MemberPositionBundle[] | null
  beamsPositionGrouping: MemberPositionBundle[] | null
  purlinsPositionGrouping: MemberPositionBundle[] | null
  columnsPositionGrouping: MemberPositionBundle[] | null
  memberCheckSettings: SettingsOnMember[]
  memberCheckSettingsFromLastComputation: SettingsOnMember[]
  crossSectionsFromLastComputation: ElementCSAssignment[]

  // used to have an intermediate state for displaying in the scene that can be
  // resetted
  intermediateBundle: MemberPositionBundle | null

  previewLoads: ElementLoad[] | null

  showPreviewLoads: boolean

  hiddenLoads: string[]

  positionFilters: Record<PositionGroupingType, GridFilterModel | undefined>
  positionSort: Record<PositionGroupingType, GridSortModel | undefined>
  positionPagination: Record<PositionGroupingType, GridPaginationModel | undefined>

  resultElementVisibility: Record<ResultElement, boolean>

  globalTabsSelectedElement: string | null

  cornerConnectionAdditionalLoadProposal: CornerConnectionAdditionalLoadProposalStates

  selectedProposalAnchor: string | null
}

interface ResultsStoreType extends ResultsInitialState {
  clear: () => void
  clearForCheckReRun: () => void

  setSegmentForces: (segmentForces: SegmentForces[]) => void
  setStiffeningSegmentChecks: (stiffeningSegmentChecks: StiffeningSegmentCheck[]) => void
  setAnchorChecks: (anchorChecks: AnchorCheckOnSupport[]) => void
  setStiffeningIntervals: (stiffeningIntervals: StiffeningSegment[]) => void
  setElementLoads: (elementLoads: ElementLoad[]) => void
  setPositionLoads: (positionLoads: ElementLoad[]) => void
  setStructuralChecks: (structuralChecks: CombinedPositionCheck[]) => void
  setStandardRipChecks: (standardRipChecks: StandardRipCheck[]) => void
  setLoadTracingMap: (loadTracingMap: LoadSource[]) => void
  setVerticalTransmissionGraph: (verticalTransmissionGraph: VerticalTransmissionGraph) => void
  setTensileTransmissionGraph: (tensileTransmissionGraph: TensileTransmissionGraph) => void
  setAnchorInterventionTableData: (
    anchorInterventionTableData: AnchorInterventionsTableRowData[],
  ) => void

  setWallRipsPositionGrouping: (wallRipsPositionGrouping: MemberPositionBundle[]) => void
  setWallLintelsPositionGrouping: (wallLintelsPositionGrouping: MemberPositionBundle[]) => void
  setSlabBeamsPositionGrouping: (slabBeamsPositionGrouping: MemberPositionBundle[]) => void
  setRoofSlabBeamsPositionGrouping: (roofSlabBeamsPositionGrouping: MemberPositionBundle[]) => void
  setBeamsPositionGrouping: (beamsPositionGrouping: MemberPositionBundle[]) => void
  setPurlinsPositionGrouping: (purlinsPositionGrouping: MemberPositionBundle[]) => void
  setColumnsPositionGrouping: (columnsPositionGrouping: MemberPositionBundle[]) => void

  setIntermediateBundle: (intermediateBundle: MemberPositionBundle | null) => void

  setPreviewLoads: (previewLoads: ElementLoad[] | null) => void

  setShowPreviewLoads: (showPreviewLoads: boolean) => void

  setHiddenLoads: (loads: string[]) => void

  setMemberCheckSettings: (memberCheckSettings: SettingsOnMember[]) => void
  setSingleMemberCheckSetting: (settings: SettingsOnMember) => void
  setMemberCheckSettingsFromLastComputation: (memberCheckSettings: SettingsOnMember[]) => void
  setCrossSectionsFromLastComputation: (crossSectionAssignment: ElementCSAssignment[]) => void

  clearHiddenLoads: () => void

  setDesignForces: (designForces: DesignSupportForcesOnPosition[] | null) => void

  setPositionFilter: (type: PositionGroupingType, filter: GridFilterModel | undefined) => void
  setPositionSort: (type: PositionGroupingType, filter: GridSortModel | undefined) => void
  setPositionPagination: (
    type: PositionGroupingType,
    pagination: GridPaginationModel | undefined,
  ) => void

  changeResultElementVisibility: (element: ResultElement, visibility: boolean) => void

  setGlobalTabsSelectedElement: (globalTabsSelectedElement: string | null) => void

  setCornerConnectionAdditionalLoadProposal: (
    cornerConnectionAdditionalLoadProposal: CornerConnectionAdditionalLoadProposalStates,
  ) => void

  setSelectedProposalAnchor: (selectedProposalAnchor: string | null) => void
}

const initialState: ResultsInitialState = {
  segmentForces: [],
  stiffeningSegmentChecks: [],
  anchorChecks: [],
  stiffeningIntervals: [],
  elementLoads: [],
  positionLoads: [],
  structuralChecks: [],
  standardRipChecks: [],
  loadTracingMap: [],
  designForces: [],
  verticalTransmissionGraph: null,
  tensileTransmissionGraph: null,
  anchorInterventionTableData: null,

  wallRipsPositionGrouping: null,
  wallLintelsPositionGrouping: null,
  slabBeamsPositionGrouping: null,
  roofSlabBeamsPositionGrouping: null,
  beamsPositionGrouping: null,
  columnsPositionGrouping: null,
  purlinsPositionGrouping: null,

  intermediateBundle: null,

  previewLoads: null,

  showPreviewLoads: true,

  hiddenLoads: [],

  memberCheckSettings: [],
  memberCheckSettingsFromLastComputation: [],
  crossSectionsFromLastComputation: [],

  positionFilters: {
    'wall-rips': undefined,
    'wall-lintels': undefined,
    'slab-beams': undefined,
    beams: undefined,
    purlins: undefined,
    columns: undefined,
    'roof-slab-beams': undefined,
  },

  positionSort: {
    'wall-rips': undefined,
    'wall-lintels': undefined,
    'slab-beams': undefined,
    beams: undefined,
    purlins: undefined,
    columns: undefined,
    'roof-slab-beams': undefined,
  },

  positionPagination: {
    'wall-rips': undefined,
    'wall-lintels': undefined,
    'slab-beams': undefined,
    beams: undefined,
    purlins: undefined,
    columns: undefined,
    'roof-slab-beams': undefined,
  },

  resultElementVisibility: {
    roofSlabPositions: false,
    slabPositions: false,
    wallPositions: true,
  },

  globalTabsSelectedElement: null,

  cornerConnectionAdditionalLoadProposal: { type: 'idle' },

  selectedProposalAnchor: null,
}

const createStore = () =>
  create<ResultsStoreType>(
    combine(cloneDeep(initialState), set => ({
      clear: () => set(cloneDeep(initialState)),
      clearForCheckReRun: () =>
        set(
          produce(state => {
            state.stiffeningSegmentChecks = []
            state.anchorChecks = []
            state.structuralChecks = []
            state.standardRipChecks = []
          }),
        ),

      setSegmentForces: (segmentForces: SegmentForces[]) => set({ segmentForces }),
      setStiffeningSegmentChecks: (stiffeningSegmentChecks: StiffeningSegmentCheck[]) =>
        set({ stiffeningSegmentChecks }),
      setAnchorChecks: (anchorChecks: AnchorCheckOnSupport[]) => set({ anchorChecks }),
      setStiffeningIntervals: (stiffeningIntervals: StiffeningSegment[]) =>
        set({ stiffeningIntervals }),
      setElementLoads: (elementLoads: ElementLoad[]) => set({ elementLoads }),
      setPositionLoads: (positionLoads: ElementLoad[]) => set({ positionLoads }),
      setStructuralChecks: (structuralChecks: CombinedPositionCheck[]) => set({ structuralChecks }),
      setStandardRipChecks: (standardRipChecks: StandardRipCheck[]) => set({ standardRipChecks }),
      setLoadTracingMap: (loadTracingMap: LoadSource[]) => set({ loadTracingMap }),
      setVerticalTransmissionGraph: (verticalTransmissionGraph: VerticalTransmissionGraph) =>
        set({ verticalTransmissionGraph }),
      setTensileTransmissionGraph: (tensileTransmissionGraph: TensileTransmissionGraph) =>
        set({ tensileTransmissionGraph }),

      setAnchorInterventionTableData: (
        anchorInterventionTableData: AnchorInterventionsTableRowData[],
      ) => set({ anchorInterventionTableData }),

      setWallRipsPositionGrouping: (wallRipsPositionGrouping: MemberPositionBundle[]) =>
        set({ wallRipsPositionGrouping }),
      setWallLintelsPositionGrouping: (wallLintelsPositionGrouping: MemberPositionBundle[]) =>
        set({ wallLintelsPositionGrouping }),
      setSlabBeamsPositionGrouping: (slabBeamsPositionGrouping: MemberPositionBundle[]) =>
        set({ slabBeamsPositionGrouping }),

      setRoofSlabBeamsPositionGrouping: (roofSlabBeamsPositionGrouping: MemberPositionBundle[]) =>
        set({ roofSlabBeamsPositionGrouping }),

      setBeamsPositionGrouping: (beamsPositionGrouping: MemberPositionBundle[]) =>
        set({ beamsPositionGrouping }),
      setPurlinsPositionGrouping: (purlinsPositionGrouping: MemberPositionBundle[]) =>
        set({ purlinsPositionGrouping }),
      setColumnsPositionGrouping: (columnsPositionGrouping: MemberPositionBundle[]) =>
        set({ columnsPositionGrouping }),

      setIntermediateBundle: (intermediateBundle: MemberPositionBundle | null) =>
        set({ intermediateBundle }),

      setPreviewLoads: (previewLoads: ElementLoad[] | null) => set({ previewLoads }),

      setShowPreviewLoads: (showPreviewLoads: boolean) => set({ showPreviewLoads }),

      setHiddenLoads: (hiddenLoads: string[]) => set({ hiddenLoads }),

      clearHiddenLoads: () => set({ hiddenLoads: [] }),

      setMemberCheckSettings: (memberCheckSettings: SettingsOnMember[]) =>
        set({ memberCheckSettings }),

      setMemberCheckSettingsFromLastComputation: (memberCheckSettings: SettingsOnMember[]) =>
        set({ memberCheckSettingsFromLastComputation: memberCheckSettings }),

      setCrossSectionsFromLastComputation: (crossSections: ElementCSAssignment[]) =>
        set({ crossSectionsFromLastComputation: crossSections }),

      setSingleMemberCheckSetting: (settings: SettingsOnMember) => {
        set(state => {
          const tempSettings = state.memberCheckSettings?.filter(
            setting => setting.element_guid !== settings.element_guid,
          )
          if (!tempSettings) return state

          const newSettings = [...tempSettings, settings]
          return { ...state, memberCheckSettings: newSettings }
        })
      },

      setDesignForces: (designForces: DesignSupportForcesOnPosition[] | null) =>
        set({ designForces }),

      setPositionFilter: (type: PositionGroupingType, filter: GridFilterModel | undefined) =>
        set(state => ({
          ...state,
          positionFilters: {
            ...state.positionFilters,
            [type]: filter,
          },
        })),

      setPositionSort: (type: PositionGroupingType, filter: GridSortModel | undefined) =>
        set(state => ({
          ...state,
          positionSort: {
            ...state.positionSort,
            [type]: filter,
          },
        })),

      setPositionPagination: (
        type: PositionGroupingType,
        pagination: GridPaginationModel | undefined,
      ) =>
        set(state => ({
          ...state,
          positionPagination: {
            ...state.positionPagination,
            [type]: pagination,
          },
        })),

      changeResultElementVisibility: (element: ResultElement, visibility: boolean) =>
        set(state => ({
          resultElementVisibility: {
            ...state.resultElementVisibility,
            [element]: visibility,
          },
        })),

      setGlobalTabsSelectedElement: (globalTabsSelectedElement: string | null) =>
        set({ globalTabsSelectedElement }),

      setCornerConnectionAdditionalLoadProposal: (
        cornerConnectionAdditionalLoadProposal: CornerConnectionAdditionalLoadProposalStates,
      ) => set({ cornerConnectionAdditionalLoadProposal }),

      setSelectedProposalAnchor: (selectedProposalAnchor: string | null) =>
        set({ selectedProposalAnchor }),
    })),
  )

const useResultsStore = createStore()

const ResultsStoreProvider = ({ children }: Props): ReactElement => {
  const clear = useResultsStore(state => state.clear)

  useEffect(() => {
    return () => clear()
  }, [])

  return <>{children}</>
}

export { ResultsStoreProvider, useResultsStore }
