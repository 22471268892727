import { ReactElement, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { getElementAssemblyType, multiEditConfig } from '@editorUtils'
import { AxiosError } from 'axios'
import { every, isEqual, omit } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Button, Stack, Typography } from '@mui/material'
import { useSystemManagerStore } from '@editorStores'
import {
  useElementTypes,
  useGuidToCrossSection,
  useGuidToElementIncludingRips,
  useResultsInvalidation,
} from '@editorHooks'
import {
  AssemblyAssignmentForm,
  ElementAssembliesTable,
  ElementCSTable,
  SingleElementCSForm,
} from '@editorComponents'
import { getMemberCheckSettings, getElementCrossSectionAssignment } from '@queries'
import { saveElementCrossSectionAssignment } from '@mutations'
import { buildErrorMessage } from 'src/constants'

interface Props {
  resetElements?: () => void
  selectedElements: string[]
  invalidateCSResults?: boolean
}

const MultiEdit = ({
  resetElements,
  invalidateCSResults,
  selectedElements,
}: Props): ReactElement => {
  const { projectId } = useParams()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const guidToElement = useGuidToElementIncludingRips()
  const elementTypes = useElementTypes()
  const guidToCrossSection = useGuidToCrossSection()

  const setElementCrossSections = useSystemManagerStore(state => state.setCrossSections)

  const invalidateResultsQueries = useResultsInvalidation()

  const elements = useMemo(
    () => selectedElements.map(guid => guidToElement[guid]),
    [selectedElements, guidToElement],
  )

  const multiEditConfigSelectedElements = useMemo(
    () => (selectedElements.length === 0 ? null : multiEditConfig(elements)),
    [elements, selectedElements.length],
  )

  const selectedElementsMode = useMemo(() => {
    if (multiEditConfigSelectedElements?.onlyInnerWalls) return 'Innenwände'
    if (multiEditConfigSelectedElements?.onlyOuterWalls) return 'Außenwände'
    if (multiEditConfigSelectedElements?.onlyVerticalRoofSlabs) return 'Dächer'
    if (multiEditConfigSelectedElements?.onlyVerticalSlabs) return 'Decken'
    if (multiEditConfigSelectedElements?.onlyLineLike) return 'Line-Like (Unterzüge, Rippen etc.)'

    return 'Keine Einschränkung'
  }, [
    multiEditConfigSelectedElements?.onlyInnerWalls,
    multiEditConfigSelectedElements?.onlyLineLike,
    multiEditConfigSelectedElements?.onlyOuterWalls,
    multiEditConfigSelectedElements?.onlyVerticalRoofSlabs,
    multiEditConfigSelectedElements?.onlyVerticalSlabs,
  ])

  const combinedProperties = useMemo(() => {
    const elements = selectedElements.map(guid => guidToElement[guid])

    if (selectedElements.length === 0 || !multiEditConfigSelectedElements) return null

    const {
      onlyInnerWalls,
      onlyOuterWalls,
      onlyVerticalSlabs: onlySlabs,
      onlyVerticalRoofSlabs: onlyRoofSlabs,
      onlyLineLike,
    } = multiEditConfigSelectedElements

    const firstElement = elements[0]

    let assemblyConfig

    const elementType =
      'type' in firstElement && firstElement.type
        ? getElementAssemblyType(firstElement.type)
        : undefined

    if (elementType) {
      assemblyConfig = {
        show: onlyInnerWalls || onlyOuterWalls || onlySlabs || onlyRoofSlabs,
        elementType,
      }
    }

    let crossSectionConfig

    if ('position_guid' in firstElement || 'start' in firstElement.shape) {
      const guid = 'position_guid' in firstElement ? firstElement.position_guid : firstElement.guid
      const type = elementTypes[guid]
      crossSectionConfig = {
        show: onlyLineLike,
        referenceElement: {
          guid,
          type,
        },
      }
    }

    return {
      assemblyConfig,
      crossSectionConfig,
    }
  }, [elementTypes, guidToElement, multiEditConfigSelectedElements, selectedElements])

  const csWarning = useMemo(() => {
    if (selectedElements.length === 0) return

    const referenceCS = omit(guidToCrossSection[selectedElements[0]], 'element_guid')

    const allEqual = every(selectedElements, guid => {
      const csWithoutElement = omit(guidToCrossSection[guid], 'element_guid')
      return isEqual(csWithoutElement, referenceCS)
    })

    return allEqual ? undefined : 'Elemente haben unterschiedliche Querschnitte'
  }, [guidToCrossSection, selectedElements])

  const { mutateAsync, isLoading } = useMutation(
    (data: CrossSection) => {
      // Update the local state directly so no refetch is required
      setElementCrossSections(selectedElements, data)

      return saveElementCrossSectionAssignment.request(projectId as string, {
        element_guids: selectedElements,
        cross_section: data,
      })
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))

        if (invalidateCSResults) invalidateResultsQueries(projectId as string)
        else
          enqueueSnackbar(
            'Sie müssen die Berechnung neu starten um die neuen Einstellungen zu verwenden',
            {
              variant: 'warning',
            },
          )

        enqueueSnackbar('Querschnitt-Zuweisung erfolgreich gespeichert', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
        // Refetch onError to ensure local state is in sync with backend again
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
      },
    },
  )

  return (
    <Stack direction="column" spacing={2} p={2}>
      <Stack p={1} border={1} borderColor="grey.200" borderRadius={1} spacing={1}>
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          Ausgewählt:{' '}
          <text data-cy="multi-edit-selected-elements-text">{selectedElements.length}</text>
        </Typography>
        <Typography mb={1} textAlign="center">
          Wählen Sie weitere Elemente an: {selectedElementsMode}
        </Typography>

        {resetElements && (
          <Button onClick={resetElements} size="small" variant="outlined" fullWidth>
            Auswahl aufheben
          </Button>
        )}
      </Stack>

      {combinedProperties?.assemblyConfig?.show && (
        <Stack direction="column" spacing={2}>
          <AssemblyAssignmentForm
            elementType={combinedProperties?.assemblyConfig.elementType}
            selectedElements={selectedElements}
            showSelectedElementsNumber={false}
          />
          <ElementAssembliesTable elementGuids={selectedElements} />
        </Stack>
      )}

      {combinedProperties?.crossSectionConfig && (
        <Stack direction="column" spacing={2}>
          <SingleElementCSForm
            selectedElement={combinedProperties?.crossSectionConfig.referenceElement.guid}
            elementType={
              combinedProperties?.crossSectionConfig.referenceElement.type as ElementTypes
            }
            warning={csWarning}
            alternateMutation={{ mutateAsync, isLoading }}
            disableIfNotDirty={false}
          />
          <ElementCSTable elementGuids={selectedElements} />
        </Stack>
      )}
    </Stack>
  )
}

export default MultiEdit
