import { ReactElement } from 'react'
import { toNumber } from 'lodash-es'
import { ExpandMore } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography,
  Button,
  Box,
  InputAdornment,
} from '@mui/material'
import { Form, TextField, UnitConvertedField } from '@ui/forms'
import { useResultsStore } from '@editorStores'
import { useElementLabel, useSupportGuidToElement } from '@editorHooks'
import { newProposalSchema } from '../../schema'
import FormFields from '../FormFields'

interface Props {
  expanded: boolean
  onExpand: (expanded: boolean) => void
  onFetchNew: () => void
  isLoadingNew: boolean

  anchorGuid: string
  wallGuid: string

  collectionLeft?: number
  collectionRight?: number

  wallLength: number
  supportPosition: number
  resultingForce: number
  resultingFormula: string

  onAccept: (formula: string, value: string, rawFormula: string, maxLoad: number) => void
  disableAccept: boolean

  biDirection?: boolean
}

const FormBase = ({
  expanded,
  onExpand,
  onFetchNew,
  isLoadingNew,
  anchorGuid,
  wallGuid,
  collectionLeft,
  collectionRight,
  wallLength,

  supportPosition,
  resultingForce,
  resultingFormula,
  onAccept,
  disableAccept,
  biDirection = false,
}: Props): ReactElement => {
  const getLabel = useElementLabel()
  const tensileGraph = useResultsStore(state => state.tensileTransmissionGraph)
  const supportGuidToElement = useSupportGuidToElement(tensileGraph)

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, expanded) => {
        if (isLoadingNew) return
        onExpand(expanded)
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="body2">
          Auflaust aus {getLabel(wallGuid)} auf{' '}
          {getLabel(supportGuidToElement?.[anchorGuid]?.guid || 'unbekannt')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" spacing={2}>
          <Form
            onSubmit={() => {
              onFetchNew()
            }}
            validationSchema={newProposalSchema}
            defaultValues={{
              anchorGuid,
              wallGuid,
              collectionLeft: collectionLeft || null,
              collectionRight: collectionRight || null,
            }}
            validationContext={{
              wallLength,
              relSupportPosition: supportPosition,
            }}
          >
            <Stack direction="column" spacing={2}>
              <FormFields
                relSupportPosition={supportPosition}
                wallLength={wallLength}
                biDirection={biDirection}
              />

              <Box>
                <LoadingButton
                  type="submit"
                  variant="outlined"
                  color="primary"
                  loading={isLoadingNew}
                >
                  Neu ermitteln
                </LoadingButton>
              </Box>
            </Stack>
          </Form>

          <Divider />

          <Form
            onSubmit={state => {
              onAccept(
                `=-MIN(${state.formula}, ${state.maxForce / 1000})`,
                state.load > state.maxForce ? state.maxForce : state.load,
                state.formula,
                toNumber(state.maxForce) / 1000,
              )
            }}
            defaultValues={{
              load: resultingForce,
              formula: resultingFormula,
              maxForce: 1000,
            }}
            enableReinitialize
          >
            <Stack direction="column" spacing={2}>
              <TextField
                name="formula"
                label="Zusätzliche Last (Formel)"
                disabled
                InputProps={{
                  endAdornment: <InputAdornment position="end">kN</InputAdornment>,
                }}
              />
              <UnitConvertedField
                name="load"
                label="Zusätzliche Last"
                disabled
                conversionFactor={1000}
                unitLabel="kN"
              />

              <Stack direction="row" alignItems="center" flex={1}>
                <UnitConvertedField
                  label="Maximale Last auf Eckverschraubung"
                  type="number"
                  size="small"
                  sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
                  name="maxForce"
                  unitLabel="kN"
                  conversionFactor={1000}
                  inputProps={{ disableDecimalScale: true }}
                  tooltip={{ text: 'Die maximale Last wird in die Formel eingebaut' }}
                />
              </Stack>

              <Box>
                <Button type="submit" variant="contained" color="primary" disabled={disableAccept}>
                  Akzeptieren
                </Button>
              </Box>
            </Stack>
          </Form>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default FormBase
