import { ReactElement, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { Typography, Container, Button } from '@mui/material'
import { AddButton } from '@ui/actions'
import { LoadingIndicator } from '@ui/feedback'
import { Box } from '@ui/structure'
import { useConfigStore } from '@stores'
import { getProjects } from '@queries'
import { createProject, deleteProject, duplicateProject, editProject } from '@mutations'
import { ProjectDataDialog, ProjectTable } from './components'

const Home = (): ReactElement => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [projectToEdit, setProjectToEdit] = useState<ProjectData | null>(null)

  const { errors } = useConfigStore()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(getProjects.key, getProjects.request, {
    onError: () => {
      enqueueSnackbar('Fehler beim Laden der Projekte', { variant: 'error' })
    },
  })

  const { mutate: handleDelete } = useMutation(deleteProject.request, {
    mutationKey: deleteProject.key,
    onSuccess: async () => {
      await queryClient.invalidateQueries(getProjects.key)
      enqueueSnackbar('Project erfolgreich gelöscht', { variant: 'success' })
    },
    onError: () => {
      enqueueSnackbar(errors.deleteProject, { variant: 'error' })
    },
  })

  const { mutate: handleDuplicate } = useMutation(duplicateProject.request, {
    mutationKey: duplicateProject.key,
    onMutate: async () => {
      enqueueSnackbar('Dupliziere Projekt', { variant: 'info' })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(getProjects.key)
      enqueueSnackbar('Project erfolgreich dupliziert', { variant: 'success' })
    },
    onError: () => {
      enqueueSnackbar('Fehler beim Duplizieren des Projekts', { variant: 'error' })
    },
  })

  const [invalidError, setInvalidError] = useState<string | null>(null)

  const { mutate: createMutate, isLoading: createLoading } = useMutation(createProject.request, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getProjects.key)
      enqueueSnackbar('Projekt erfolgreich angelegt', { variant: 'success' })
      setCreateDialogOpen(false)
    },
    onError: (error: AxiosError) => {
      const status = error?.response?.status

      if (status === 402) {
        enqueueSnackbar(
          'Sie haben keine Projekte mehr verfügbar. Bitte wenden Sie sich an den Support um neue zu kaufen',
          { variant: 'error' },
        )
      } else if (status === 422) {
        enqueueSnackbar('Invalides Modell', { variant: 'error' })

        setInvalidError(JSON.stringify(error.response?.data.detail, null, 4))
      } else {
        enqueueSnackbar('Fehler beim Anlegen des Projektes', { variant: 'error' })
      }
    },
  })

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    (data: ProjectData) => {
      return editProject.request(data.guid, data)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjects.key)
        enqueueSnackbar('Projekt erfolgreich bearbeitet', { variant: 'success' })
        setProjectToEdit(null)
      },
      onError: (error: AxiosError) => {
        const status = error?.response?.status

        if (status === 402) {
          enqueueSnackbar(
            'Sie haben keine Projekte mehr verfügbar. Bitte wenden Sie sich an den Support um neue zu kaufen',
            { variant: 'error' },
          )
        } else {
          enqueueSnackbar('Fehler beim Anlegen des Projektes', { variant: 'error' })
        }
      },
    },
  )

  if (isLoading || !data) {
    return <LoadingIndicator />
  }

  return (
    <>
      <Container maxWidth="lg">
        <Box mb={3}>
          <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">Ihre Projekte {data && `(${data.length})`}</Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box mr={1}>
                <Button variant="outlined" onClick={() => navigate('/manager')}>
                  System Manager
                </Button>
              </Box>
              <AddButton onClick={() => setCreateDialogOpen(true)} data-cy="btn-create-project">
                Neues Projekt
              </AddButton>
            </Box>
          </Box>
          <ProjectTable
            projects={data}
            onClick={({ guid }) => navigate(`/editor/${guid}`)}
            onDelete={({ guid }) => handleDelete(guid)}
            onEdit={projectData => {
              setProjectToEdit(projectData)
            }}
            onDuplicate={({ guid }) => handleDuplicate(guid)}
          />
        </Box>
        <Box mb={3}>
          <Typography variant="h4">Allgemein</Typography>
        </Box>
      </Container>
      {createDialogOpen && (
        <ProjectDataDialog
          open
          onClose={() => setCreateDialogOpen(false)}
          onSubmit={createMutate}
          isLoading={createLoading}
          invalidError={invalidError}
          setInvalidError={setInvalidError}
          project={null}
        />
      )}
      {projectToEdit && (
        <ProjectDataDialog
          open
          onClose={() => {
            setProjectToEdit(null)
          }}
          onSubmit={editMutate}
          isLoading={editLoading}
          invalidError={invalidError}
          setInvalidError={setInvalidError}
          project={projectToEdit}
        />
      )}
    </>
  )
}

export default Home
