import { ReactElement, useCallback, useMemo, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams } from 'react-router-dom'
import {
  useAnchorTable,
  useCornerConnectionAdditionalLoadProposalState,
  useResultsQueries,
  useResultsQueryParams,
} from '@resultsHooks'
import { MultiEdit } from '@structuralPlanningComponents'
import { ArrowBack, Error } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, CircularProgress, Stack } from '@mui/material'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { SwitchLabeled } from '@ui/actions'
import { Info, LoadingIndicator } from '@ui/feedback'
import { NavbarPortalCenter, NavBreadcrumbs } from '@ui/navigation'
import { Drawer, LayoutDrawer } from '@ui/structure'
import BottomDrawer from '@ui/structure/BottomDrawer'
import { useControlStore, useResultsStore } from '@editorStores'
import { useSelectionMode } from '@editorHooks'
import { FilterPortal } from '@editorComponents'
import { appConfig } from 'src/constants'
import SceneControlled from '../../components/SceneControlled'
import { ResultsScene, LocalResults, GlobalTabs, AnchorDataGrid2 } from './components'
import AnchorDataGrid from './components/AnchorDataGrid'
import { AnchorDataGrid2Ref } from './components/AnchorDataGrid2/components/AnchorDataGridTable'
import { loadSources } from './components/AnchorDataGrid2/constants'
import CornerConnectionProposalForm from './components/CornerConnectionAdditionalLoadProposalForm'

const Results = (): ReactElement => {
  const { projectId } = useParams()

  const { isSelectionMode, unsetSelectionMode } = useSelectionMode()

  const {
    /**
     * @deprecated Remove once new anchor table has been shipped
     */
    tableRef,
    setRef,
  } = useAnchorTable()

  const anchorTable2Ref = useRef<AnchorDataGrid2Ref>()

  const newMuiDataGrid = appConfig.ff_anchor_mui_datagrid

  // APP STATE

  const isTapelineDrawing = useTapelineStore(state => state.isDrawing)
  const actionMode = useControlStore(state => state.actionMode)
  const isDrawerExpanded = useControlStore(state => state.isDrawerExpanded)
  const setIsDrawerExpanded = useControlStore(state => state.setIsDrawerExpanded)
  const isAnchorCalculationMode = useControlStore(state => state.isAnchorCalculationMode)
  const setIsAnchorCalculationMode = useControlStore(state => state.setIsAnchorCalculationMode)
  const isBottomDrawerExpanded = useControlStore(state => state.isBottomDrawerExpanded)
  const setIsBottomDrawerExpanded = useControlStore(state => state.setIsBottomDrawerExpanded)
  const selectedProposalAnchor = useResultsStore(state => state.selectedProposalAnchor)

  const elementLoads = useResultsStore(state => state.elementLoads)
  const positionLoads = useResultsStore(state => state.positionLoads)
  const visibility = useResultsStore(state => state.resultElementVisibility)
  const changeResultElementVisibility = useResultsStore(
    state => state.changeResultElementVisibility,
  )

  const {
    cornerConnectionState,
    isLoadingProposal,
    abortHelper,
    fetchWithInterval,
    isLoadingWithInterval,
  } = useCornerConnectionAdditionalLoadProposalState()

  // QUERY PARAMS

  const {
    params: { selectedElements },
    modes: { isLocalMode, isMultiEditMode },
    actions: { resetSelection, setIsLocalMode, selectConnector, resetSelectedElements },
  } = useResultsQueryParams()

  // QUERIES

  const { compute, computeChecks, isLoadingInitial, initialLoadingSuccess, isLoadingResults } =
    useResultsQueries(projectId as string)

  // HOTKEYS

  const escAction = useCallback(() => {
    if (cornerConnectionState.type !== 'idle') abortHelper()
    else if (isSelectionMode) unsetSelectionMode()
    else if (isLocalMode) setIsLocalMode(false)
    else resetSelection()
  }, [
    isSelectionMode,
    unsetSelectionMode,
    resetSelection,
    isLocalMode,
    setIsLocalMode,
    cornerConnectionState,
    abortHelper,
  ])

  useHotkeys('esc', escAction, { enabled: !isTapelineDrawing && actionMode !== 'hide' }, [
    escAction,
  ])

  const disableAllExcept = useMemo(() => {
    if (cornerConnectionState.type === 'idle') return

    if (
      cornerConnectionState.type === 'selected-anchor' ||
      cornerConnectionState.type === 'selected-anchor-and-wall'
    ) {
      return {
        anchorGuid: cornerConnectionState.anchorGuid,
        fields: [cornerConnectionState.field, 'comment'],
      }
    }

    if (selectedProposalAnchor === cornerConnectionState.anchorGuid) {
      return {
        anchorGuid: cornerConnectionState.anchorGuid,
        fields: [cornerConnectionState.field, 'comment'],
      }
    } else if (selectedProposalAnchor === cornerConnectionState.biAnchorGuid) {
      return {
        anchorGuid: cornerConnectionState.biAnchorGuid,
        fields: ['manual_load_1', 'manual_source_1', 'comment'],
      }
    }
  }, [cornerConnectionState, selectedProposalAnchor])

  return (
    <>
      <FilterPortal>
        <SwitchLabeled
          checked={visibility.wallPositions}
          onChange={() => changeResultElementVisibility('wallPositions', !visibility.wallPositions)}
          label="Wandpositionen"
        />
        <SwitchLabeled
          checked={visibility.slabPositions}
          onChange={() => changeResultElementVisibility('slabPositions', !visibility.slabPositions)}
          label="Deckenbalken"
        />
        <SwitchLabeled
          checked={visibility.roofSlabPositions}
          onChange={() =>
            changeResultElementVisibility('roofSlabPositions', !visibility.roofSlabPositions)
          }
          label="Dachbalken"
        />
      </FilterPortal>

      <NavbarPortalCenter>
        <NavBreadcrumbs extension />
      </NavbarPortalCenter>

      <LayoutDrawer>
        <SceneControlled>
          <ResultsScene />
        </SceneControlled>

        <Drawer
          variant="persistent"
          expanded={isDrawerExpanded}
          onExpand={setIsDrawerExpanded}
          bottomDrawerExpanded={isBottomDrawerExpanded}
        >
          {isMultiEditMode ? (
            <MultiEdit
              selectedElements={selectedElements || []}
              resetElements={resetSelectedElements}
            />
          ) : cornerConnectionState.type !== 'idle' ? (
            [
              <>
                {!!isLoadingProposal && <LoadingIndicator />}
                {(cornerConnectionState.type === 'selected-anchor-and-wall' ||
                  cornerConnectionState.type === 'selected-anchor') && (
                  <Box m={2}>
                    <Alert severity="info">Noch kein Vorschlag erstellt</Alert>
                  </Box>
                )}
                {(cornerConnectionState.type === 'proposal-created' ||
                  cornerConnectionState.type === 'proposal-changed') && (
                  <>
                    {newMuiDataGrid ? (
                      <CornerConnectionProposalForm
                        anchorGuid={cornerConnectionState.anchorGuid}
                        wallGuid={cornerConnectionState.wallGuid}
                        initialData={cornerConnectionState.proposal}
                        biAnchorGuid={cornerConnectionState.biAnchorGuid}
                        biWallGuid={cornerConnectionState.biWallGuid}
                        biInitialData={cornerConnectionState.biProposal}
                        onAccept={async (
                          formula: string,
                          value: string,
                          rawFormula: string,
                          maxForce: number,
                        ) => {
                          if (
                            cornerConnectionState.type !== 'proposal-created' &&
                            cornerConnectionState.type !== 'proposal-changed'
                          )
                            return

                          await anchorTable2Ref.current?.insertCell(
                            cornerConnectionState.anchorGuid,
                            cornerConnectionState.field,
                            `-MIN(${rawFormula}, ${maxForce})`,
                          )
                          anchorTable2Ref.current?.extend(
                            cornerConnectionState.anchorGuid,
                            'comment',
                            `Eckverschraubung maximale Last: ${maxForce} kN`,
                          )
                        }}
                        onAcceptBi={async (
                          formula: string,
                          value: string,
                          rawFormula: string,
                          maxForce: number,
                        ) => {
                          if (!cornerConnectionState.biAnchorGuid || !anchorTable2Ref.current)
                            return

                          await anchorTable2Ref.current?.insertCell(
                            cornerConnectionState.biAnchorGuid,
                            'manual_load_1',
                            `-MIN(${rawFormula}, ${maxForce})`,
                          )
                          await anchorTable2Ref.current?.insertCell(
                            cornerConnectionState.biAnchorGuid,
                            'manual_source_1',
                            loadSources.wallCorner,
                          )
                          anchorTable2Ref.current?.extend(
                            cornerConnectionState.anchorGuid,
                            'comment',
                            `Eckverschraubung maximale Last: ${maxForce} kN`,
                          )
                        }}
                        isLoadingWithInterval={isLoadingWithInterval}
                        fetchWithInterval={fetchWithInterval}
                        onExpand={() => {
                          anchorTable2Ref.current?.selectCell(
                            cornerConnectionState.anchorGuid,
                            cornerConnectionState.field,
                          )
                          selectConnector(cornerConnectionState.anchorGuid)
                        }}
                        onExpandBi={() => {
                          if (!cornerConnectionState.biAnchorGuid) return

                          anchorTable2Ref.current?.selectCell(
                            cornerConnectionState.biAnchorGuid,
                            'manual_load_1',
                          )
                          selectConnector(cornerConnectionState.biAnchorGuid)
                        }}
                      />
                    ) : (
                      <CornerConnectionProposalForm
                        anchorGuid={cornerConnectionState.anchorGuid}
                        wallGuid={cornerConnectionState.wallGuid}
                        initialData={cornerConnectionState.proposal}
                        biAnchorGuid={cornerConnectionState.biAnchorGuid}
                        biWallGuid={cornerConnectionState.biWallGuid}
                        biInitialData={cornerConnectionState.biProposal}
                        onAccept={(formula: string) => {
                          if (
                            cornerConnectionState.type !== 'proposal-created' &&
                            cornerConnectionState.type !== 'proposal-changed'
                          )
                            return

                          tableRef.current?.selectCell(
                            cornerConnectionState.rowIndex,
                            cornerConnectionState.columnIndex,
                          )
                          tableRef.current?.insertCurrentCell(formula)
                        }}
                        onAcceptBi={(formula: string) => {
                          if (!cornerConnectionState.biAnchorGuid || !tableRef.current) return

                          const [row, column] = tableRef.current.selectSupportLoadInput(
                            cornerConnectionState.biAnchorGuid,
                          )
                          tableRef.current?.insertCell(row, column, formula)
                          tableRef.current?.insertCell(row, column + 1, 'Wand über Eck ')
                        }}
                        isLoadingWithInterval={isLoadingWithInterval}
                        fetchWithInterval={fetchWithInterval}
                        onExpand={() => {
                          tableRef.current?.selectCell(
                            cornerConnectionState.rowIndex,
                            cornerConnectionState.columnIndex,
                          )
                          selectConnector(cornerConnectionState.anchorGuid)
                        }}
                        onExpandBi={() => {
                          if (!cornerConnectionState.biAnchorGuid) return

                          tableRef.current?.selectSupportLoadInput(
                            cornerConnectionState.biAnchorGuid,
                          )
                          selectConnector(cornerConnectionState.biAnchorGuid)
                        }}
                      />
                    )}
                  </>
                )}
              </>,
              <Stack
                direction="column"
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                spacing={2}
                key="actions"
              >
                <Button
                  startIcon={<ArrowBack />}
                  onClick={abortHelper}
                  variant="outlined"
                  data-cy="global-view-btn"
                >
                  Eingabehilfe schließen
                </Button>
              </Stack>,
            ]
          ) : (
            [
              <>
                {isLoadingInitial ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    {initialLoadingSuccess && elementLoads && positionLoads ? (
                      <>{isLocalMode ? <LocalResults /> : <GlobalTabs />}</>
                    ) : (
                      <Box
                        display="flex"
                        flexGrow={1}
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                      >
                        <Info icon={<Error color="error" />}>Daten noch nicht verfügbar</Info>
                      </Box>
                    )}
                  </>
                )}
              </>,

              <Stack
                direction="column"
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                spacing={2}
                key="actions"
              >
                {!isLoadingInitial && isLoadingResults && (
                  <Box>
                    <CircularProgress data-cy="loading-results-indicator" size={28} />
                  </Box>
                )}
                <Box>
                  {isLocalMode ? (
                    <Button
                      startIcon={<ArrowBack />}
                      onClick={() => setIsLocalMode(false)}
                      variant="outlined"
                      data-cy="global-view-btn"
                    >
                      Global
                    </Button>
                  ) : (
                    <Stack
                      direction="row"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-evenly"
                      spacing={2}
                    >
                      <LoadingButton
                        variant="contained"
                        onClick={() => computeChecks(projectId as string)}
                        disabled={isLoadingResults}
                        data-cy="recalculate-checks"
                      >
                        Nachweise berechnen
                      </LoadingButton>
                      <LoadingButton
                        variant="contained"
                        onClick={() => compute(projectId as string)}
                        disabled={isLoadingResults}
                        data-cy="recalculate-results"
                      >
                        Ergebnisse berechnen
                      </LoadingButton>
                    </Stack>
                  )}
                </Box>
              </Stack>,
            ]
          )}
        </Drawer>

        {newMuiDataGrid ? (
          <AnchorDataGrid2
            ref={ref => (anchorTable2Ref.current = ref || undefined)}
            disallowSelection={cornerConnectionState.type !== 'idle'}
            disableAllExcept={disableAllExcept}
          />
        ) : (
          <BottomDrawer
            isOpen={isAnchorCalculationMode}
            setIsOpen={setIsAnchorCalculationMode}
            expanded={isBottomDrawerExpanded}
            setIsDrawerExpanded={setIsBottomDrawerExpanded}
            sideDrawerExpanded={isDrawerExpanded}
            isLoading={false}
          >
            <AnchorDataGrid
              ref={setRef}
              disallowSelection={cornerConnectionState.type !== 'idle'}
            />
          </BottomDrawer>
        )}
      </LayoutDrawer>
    </>
  )
}

export default Results
