import { ReactElement, useMemo, useRef, useImperativeHandle, forwardRef } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { mapValueKey } from '@editorUtils'
import { AxiosError } from 'axios'
import { isUndefined, reduce } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { usePostHog } from 'posthog-js/react'
import { Calculate, Close } from '@mui/icons-material'
import { Box, IconButton, Stack } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid-pro'
import { Info } from '@ui/feedback'
import { Form } from '@ui/forms'
import BottomDrawer from '@ui/structure/BottomDrawer'
import { useControlStore, useResultsStore } from '@editorStores'
import { getAnchorInterventionTableData, getAnchorChecks } from '@queries'
import { formulaToNumber } from '@utils'
import { saveManualLoadsPerSupport } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import queryClient from 'src/state/client'
import AnchorDataGridTable, { AnchorDataGrid2Ref } from './components/AnchorDataGridTable'
import { anchorDataGridSchema } from './schema'

interface Props {
  disallowSelection: boolean
  disableAllExcept?: { anchorGuid: string; fields: string[] }
}

const AnchorDataGrid2 = forwardRef<AnchorDataGrid2Ref | null, Props>(
  ({ disallowSelection, disableAllExcept }: Props, ref): ReactElement => {
    const posthog = usePostHog()

    const { projectId } = useParams()
    const { enqueueSnackbar } = useSnackbar()

    const anchorInterventionTableData = useResultsStore(state => state.anchorInterventionTableData)
    const anchorChecks = useResultsStore(state => state.anchorChecks)

    const elementGuidToAnchorCheck = useMemo(
      () => (anchorChecks ? mapValueKey(anchorChecks, 'element_guid') : {}),
      [anchorChecks],
    )

    const supportGuidToAnchorName = useMemo(
      () =>
        reduce(
          anchorInterventionTableData,
          (col, el) => ({
            ...col,
            [el.support_guid]: elementGuidToAnchorCheck[el.support_guid]?.used_anchor.name,
          }),
          {} as Record<string, string>,
        ),
      [anchorInterventionTableData, elementGuidToAnchorCheck],
    )

    const defaultValues: { rows: GridRowsProp<AnchorInterventionsTableRowData> } | undefined =
      useMemo(() => {
        const rows = anchorInterventionTableData?.map(row => {
          const manualLoad1 = row.manual_loads[0]
          const manualLoad2 = row.manual_loads[1]
          const manualLoad3 = row.manual_loads[2]

          return {
            id: row.support_guid,
            ...row,
            anchor_name: supportGuidToAnchorName[row.support_guid],
            anchor_utilization: elementGuidToAnchorCheck[row.support_guid]?.utilization.toString(),
            manual_load_1: manualLoad1 ? (manualLoad1?.load / 1000).toString() : undefined,
            manual_source_1: manualLoad1?.source,
            manual_load_2: manualLoad2 ? (manualLoad2?.load / 1000).toString() : undefined,
            manual_source_2: manualLoad2?.source,
            manual_load_3: manualLoad3 ? (manualLoad3?.load / 1000).toString() : undefined,
            manual_source_3: manualLoad3?.source,
          }
        })

        if (!rows) return

        return { rows }
      }, [anchorInterventionTableData, elementGuidToAnchorCheck, supportGuidToAnchorName])

    const formRef = useRef<HTMLFormElement | null>(null)

    const { isLoading, mutateAsync } = useMutation(
      (requestData: ManualLoadOnSupport[]) => {
        return saveManualLoadsPerSupport.request(projectId, requestData)
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(getAnchorInterventionTableData.getKey(projectId))
          queryClient.invalidateQueries(getAnchorChecks.getKey(projectId))
          enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' })
        },
        onError: (error: AxiosError) => {
          enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern'), { variant: 'error' })
        },
      },
    )

    const isDrawerExpanded = useControlStore(state => state.isDrawerExpanded)
    const isAnchorCalculationMode = useControlStore(state => state.isAnchorCalculationMode)
    const setIsAnchorCalculationMode = useControlStore(state => state.setIsAnchorCalculationMode)
    const isBottomDrawerExpanded = useControlStore(state => state.isBottomDrawerExpanded)
    const setIsBottomDrawerExpanded = useControlStore(state => state.setIsBottomDrawerExpanded)

    const anchorTable2Ref = useRef<AnchorDataGrid2Ref>(null)

    // @ts-ignore
    useImperativeHandle(ref, () => anchorTable2Ref.current, [anchorTable2Ref])

    return (
      <BottomDrawer
        isOpen={isAnchorCalculationMode}
        setIsOpen={(isOpen: boolean) => {
          posthog.capture('anchor_table', {
            anchorTableOpen: isOpen,
            message: isOpen ? 'Open anchor table' : 'Close anchor table',
            projectId,
          })
          setIsAnchorCalculationMode(isOpen)
        }}
        expanded={isBottomDrawerExpanded}
        setIsDrawerExpanded={setIsBottomDrawerExpanded}
        sideDrawerExpanded={isDrawerExpanded}
        isLoading={isLoading}
      >
        {isUndefined(defaultValues) ? (
          <Stack direction="column" display="flex" flex={1}>
            <Box display="flex" justifyContent="flex-end" p={0.5}>
              <IconButton onClick={() => setIsAnchorCalculationMode(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
            <Box display="flex" flex={1} justifyContent="center" alignItems="center">
              <Box>
                <Info icon={<Calculate />}>Daten noch nicht verfügbar</Info>
              </Box>
            </Box>
          </Stack>
        ) : (
          <Form
            ref={formRef}
            validationSchema={anchorDataGridSchema}
            resetIsDirtyAfterSubmit
            onSubmit={(
              state: { rows: GridRowsProp<AnchorInterventionsTableRowData> },
              _,
              isDirty,
            ) => {
              if (!isDirty) return

              const requestData: ManualLoadOnSupport[] = state.rows.map(row => {
                const manualLoads: ManualLoad[] = []

                if (row.manual_load_1 && row.manual_source_1) {
                  manualLoads.push({
                    load: formulaToNumber(row.manual_load_1.toString()) * 1000,
                    load_formula: `${row.manual_load_1}`,
                    source: row.manual_source_1,
                  })
                }
                if (row.manual_load_2 && row.manual_source_2) {
                  manualLoads.push({
                    load: formulaToNumber(row.manual_load_2.toString()) * 1000,
                    load_formula: `${row.manual_load_2}`,
                    source: row.manual_source_2,
                  })
                }
                if (row.manual_load_3 && row.manual_source_3) {
                  manualLoads.push({
                    load: formulaToNumber(row.manual_load_3.toString()) * 1000,
                    load_formula: `${row.manual_load_3}`,
                    source: row.manual_source_3,
                  })
                }

                return {
                  support_id: row.support_guid,
                  manual_loads: manualLoads,
                  comment: row.comment,
                }
              })

              mutateAsync(requestData)
            }}
            defaultValues={defaultValues}
            style={{ display: 'flex', flex: 1, height: '100%' }}
            enableReinitialize
          >
            <AnchorDataGridTable
              ref={anchorTable2Ref}
              disallowSelection={disallowSelection}
              onSave={() => formRef.current?.requestSubmit()}
              disableAllExcept={disableAllExcept}
            />
          </Form>
        )}
      </BottomDrawer>
    )
  },
)

AnchorDataGrid2.displayName = 'AnchorDataGrid2'

export default AnchorDataGrid2
