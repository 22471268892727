import { useCallback, useEffect } from 'react'
import { useIsMutating, useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { filter, find, minBy, toNumber } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { CornerConnectionAdditionalLoadProposalStates, useResultsStore } from '@editorStores'
import { useGuidToElement, useSelectionMode } from '@editorHooks'
import { cornerConnectionProposal, cornerConnectionProposalWithInterval } from '@mutations'
import { buildErrorMessage } from 'src/constants'

const selectionKey = 'select-wall-to-connect-proposal'
const getProposalKey = 'get-proposal'

const useCornerConnectionAdditionalLoadProposalState = () => {
  const { projectId } = useParams()

  const { enqueueSnackbar } = useSnackbar()

  const { unsetSelectionMode, setSelectionMode, isSelectionMode } = useSelectionMode()

  const setCornerConnectionAdditionalLoadProposal = useResultsStore(
    state => state.setCornerConnectionAdditionalLoadProposal,
  )

  const cornerConnectionState = useResultsStore(
    state => state.cornerConnectionAdditionalLoadProposal,
  )
  const setSelectedProposalAnchor = useResultsStore(state => state.setSelectedProposalAnchor)

  const tensileGraph = useResultsStore(state => state.tensileTransmissionGraph)

  const elements = useGuidToElement()

  const isMutating = useIsMutating([getProposalKey])

  const abortHelper = useCallback(() => {
    setCornerConnectionAdditionalLoadProposal({
      type: 'idle',
    })
    unsetSelectionMode()
  }, [setCornerConnectionAdditionalLoadProposal, unsetSelectionMode])

  useEffect(() => {
    if (cornerConnectionState.type === 'selected-anchor' && !isSelectionMode) {
      abortHelper()
    }
  }, [isSelectionMode, cornerConnectionState.type, abortHelper])

  const selectAnchor = useCallback(
    (anchorGuid: string, targetRow: number, targetColumn: number, field = '') => {
      setCornerConnectionAdditionalLoadProposal({
        type: 'selected-anchor',
        anchorGuid,
        rowIndex: targetRow,
        columnIndex: targetColumn,
        field: field,
      })

      setSelectionMode({
        key: selectionKey,
        message:
          'Wähle eine Wand an, um die zusätzlich Auflast bei einer Verschraubung zu vermitteln',
      })
    },
    [setCornerConnectionAdditionalLoadProposal, setSelectionMode],
  )

  const getSupportPosition = (support: ElementSupportItem, wall: ShapeObject) => {
    const wallStart = wall.shape.points[0]
    const wallEnd = wall.shape.points[1]
    const wallLength = wallStart.distanceTo(wallEnd)
    const wallDirection = wallStart.directionTo(wallEnd)

    return wallStart.addScaledVector(
      wallDirection,
      wallLength * toNumber(support.relative_position),
    )
  }

  const selectWall = useCallback(
    (connectedWallGuid: string) => {
      if (cornerConnectionState.type !== 'selected-anchor') return

      const selectedSupport = find(
        tensileGraph?.element_supports,
        es => es.guid === cornerConnectionState.anchorGuid,
      ) as ElementSupportItem

      const selectedSupportElement = elements[selectedSupport?.element_guid] as ShapeObject

      // If there is a support in the connected wall that is near the selected
      // support we want to find it here. This indicates that a bi-directional
      // corner connection will be used and we therefore get proposals for both
      // directions then
      let otherSupport: ElementSupportItem | undefined

      if (
        selectedSupportElement.type === 'inner_walls' ||
        selectedSupportElement.type === 'outer_walls'
      ) {
        const selectedSupportPosition = getSupportPosition(selectedSupport, selectedSupportElement)

        const connectedWallSupports = filter(
          tensileGraph?.element_supports,
          support => support.element_guid === connectedWallGuid,
        )
        if (connectedWallSupports.length) {
          const connectedWall = elements[connectedWallGuid] as ShapeObject

          const wallSupportsToDistance = connectedWallSupports.map(support => {
            const otherSupportPosition = getSupportPosition(support, connectedWall)
            const supportOfConnectedWallToCurrentSupportDistance =
              selectedSupportPosition.distanceTo(otherSupportPosition)

            return [support, supportOfConnectedWallToCurrentSupportDistance] as [
              ElementSupportItem,
              number,
            ]
          })
          const nearestSupport = minBy(wallSupportsToDistance, ([, distance]) => distance)

          // we only want to have other supports within approximately 10cm
          if (nearestSupport && nearestSupport[1] < 0.1) {
            otherSupport = nearestSupport[0]
          }
        }
      }

      const otherSupportConfig = otherSupport
        ? {
            biAnchorGuid: otherSupport.guid,
            biWallGuid: selectedSupportElement.guid,
          }
        : {}

      const state: CornerConnectionAdditionalLoadProposalStates = {
        ...cornerConnectionState,
        type: 'selected-anchor-and-wall',
        wallGuid: connectedWallGuid,
        ...otherSupportConfig,
      }
      setCornerConnectionAdditionalLoadProposal(state)

      return state
    },
    [cornerConnectionState, setCornerConnectionAdditionalLoadProposal, tensileGraph, elements],
  )

  const changeProposal = useCallback(
    (
      intervalStart: number,
      intervalEnd: number,
      interval: 'left' | 'right' = 'left',
      biDirection = false,
    ) => {
      if (
        cornerConnectionState.type !== 'proposal-created' &&
        cornerConnectionState.type !== 'proposal-changed'
      )
        return

      const intervalData =
        interval === 'left'
          ? { left_interval: { lower: intervalStart, upper: intervalEnd } }
          : { right_interval: { lower: intervalStart, upper: intervalEnd } }

      if (biDirection && cornerConnectionState.biProposal) {
        setCornerConnectionAdditionalLoadProposal({
          ...cornerConnectionState,
          type: 'proposal-changed',
          biProposal: {
            ...cornerConnectionState.biProposal,
            ...intervalData,
          },
        })
      } else if (!biDirection) {
        setCornerConnectionAdditionalLoadProposal({
          ...cornerConnectionState,
          type: 'proposal-changed',
          proposal: {
            ...cornerConnectionState.proposal,
            ...intervalData,
          },
        })
      }
    },
    [cornerConnectionState, setCornerConnectionAdditionalLoadProposal],
  )

  const clearProposalInterval = useCallback(
    (interval: 'left' | 'right', biDirection = false) => {
      if (
        cornerConnectionState.type !== 'proposal-created' &&
        cornerConnectionState.type !== 'proposal-changed'
      )
        return

      const intervalData =
        interval === 'left' ? { left_interval: undefined } : { right_interval: undefined }

      if (biDirection && cornerConnectionState.biProposal) {
        setCornerConnectionAdditionalLoadProposal({
          ...cornerConnectionState,
          type: 'proposal-changed',
          biProposal: {
            ...cornerConnectionState.biProposal,
            ...intervalData,
          },
        })
      } else if (!biDirection) {
        setCornerConnectionAdditionalLoadProposal({
          ...cornerConnectionState,
          type: 'proposal-changed',
          proposal: {
            ...cornerConnectionState.proposal,
            ...intervalData,
          },
        })
      }
    },
    [cornerConnectionState, setCornerConnectionAdditionalLoadProposal],
  )

  const { mutateAsync } = useMutation(
    async (data: {
      anchorGuid: string
      wallGuid: string
      rowIndex: number
      columnIndex: number
      field: string
      biAnchorGuid?: string
      biWallGuid?: string
    }) => {
      const result = await cornerConnectionProposal.request(
        projectId as string,
        data.anchorGuid,
        data.wallGuid,
      )
      let biResult
      if (data.biAnchorGuid && data.biWallGuid) {
        biResult = await cornerConnectionProposal.request(
          projectId as string,
          data.biAnchorGuid,
          data.biWallGuid,
        )
      }

      return {
        data: result,
        rowIndex: data.rowIndex,
        columnIndex: data.columnIndex,
        field: data.field,
        wallGuid: data.wallGuid,
        anchorGuid: data.anchorGuid,

        biData: biResult,
        biAnchorGuid: data.biAnchorGuid,
        biWallGuid: data.biWallGuid,
      }
    },
    {
      mutationKey: getProposalKey,
      onSuccess: result => {
        setCornerConnectionAdditionalLoadProposal({
          type: 'proposal-created',
          proposal: result.data,
          rowIndex: result.rowIndex,
          columnIndex: result.columnIndex,
          field: result.field,
          wallGuid: result.wallGuid,
          anchorGuid: result.anchorGuid,

          biWallGuid: result.biWallGuid,
          biAnchorGuid: result.biAnchorGuid,
          biProposal: result.biData,
        })
        setSelectedProposalAnchor(result.anchorGuid)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Laden'), {
          variant: 'error',
          persist: true,
        })
      },
    },
  )

  const { mutateAsync: mutateAsyncWithInterval, isLoading: isLoadingWithInterval } = useMutation(
    async (data: {
      anchorGuid: string
      wallGuid: string
      leftInterval?: Interval
      rightInterval?: Interval
      biWall: boolean
    }) => {
      const result = await cornerConnectionProposalWithInterval.request(
        projectId as string,
        data.anchorGuid,
        data.wallGuid,
        data.leftInterval,
        data.rightInterval,
      )
      return {
        data: result,
        biWall: data.biWall,
      }
    },
    {
      onSuccess: result => {
        if (
          cornerConnectionState.type !== 'proposal-changed' &&
          cornerConnectionState.type !== 'proposal-created'
        )
          return

        const newState: CornerConnectionAdditionalLoadProposalStates = result.biWall
          ? {
              ...cornerConnectionState,
              type: 'proposal-created',
              biProposal: result.data,
            }
          : {
              ...cornerConnectionState,
              type: 'proposal-created',
              proposal: result.data,
            }

        setCornerConnectionAdditionalLoadProposal(newState)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Laden'), {
          variant: 'error',
          persist: true,
        })
      },
    },
  )

  const fetchWithInterval = useCallback(
    (biWall: boolean) => {
      if (
        cornerConnectionState.type !== 'proposal-changed' &&
        cornerConnectionState.type !== 'proposal-created'
      )
        return

      const leftAsDecimal = cornerConnectionState.proposal.left_interval
        ? {
            lower: cornerConnectionState.proposal.left_interval.lower.toString(),
            upper: cornerConnectionState.proposal.left_interval.upper.toString(),
          }
        : undefined

      const rightAsDecimal = cornerConnectionState.proposal.right_interval
        ? {
            lower: cornerConnectionState.proposal.right_interval.lower.toString(),
            upper: cornerConnectionState.proposal.right_interval.upper.toString(),
          }
        : undefined

      mutateAsyncWithInterval({
        anchorGuid: biWall
          ? (cornerConnectionState.biAnchorGuid as string)
          : cornerConnectionState.anchorGuid,
        wallGuid: biWall
          ? (cornerConnectionState.biWallGuid as string)
          : cornerConnectionState.wallGuid,
        leftInterval: leftAsDecimal,
        rightInterval: rightAsDecimal,
        biWall,
      })
    },
    [cornerConnectionState, mutateAsyncWithInterval],
  )

  return {
    selectAnchor,
    cornerConnectionState,
    isLoadingProposal: isMutating,
    abortHelper,
    changeProposal,
    fetchWithInterval,
    isLoadingWithInterval,
    proposalSelectionKey: selectionKey,
    clearProposalInterval,
    mutateAsync,
    selectWall,
  }
}

export default useCornerConnectionAdditionalLoadProposalState
