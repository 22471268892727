import { isUndefined } from 'lodash-es'
import { array, object, string, TestConfig } from 'yup'
import { AnyObject } from 'yup/lib/types'
import { formulaToNumber } from '@utils'

const testIsFormulaParsable: TestConfig<string | undefined, AnyObject> = {
  name: 'formula-is-parsable',
  test: function (value) {
    if (!isUndefined(value)) {
      try {
        formulaToNumber(value)
      } catch {
        return this.createError({
          message: `${value} kann nicht als Zellwert gesetzt werden`,
        })
      }
    }

    return true
  },
}

const anchorRow = object().shape(
  {
    // from the original row interface we only validate the following data as this
    // is the only data being sent to the backend
    manual_load_1: string()
      .when('manual_source_1', (manual_source_1, schema) =>
        manual_source_1 ? schema.required() : schema.optional(),
      )
      .test(testIsFormulaParsable),
    manual_source_1: string().when('manual_load_1', (manual_load_1, schema) =>
      manual_load_1 ? schema.required() : schema.optional(),
    ),
    manual_load_2: string()
      .when('manual_source_2', (manual_source_2, schema) =>
        manual_source_2 ? schema.required() : schema.optional(),
      )
      .test(testIsFormulaParsable),
    manual_source_2: string().when('manual_load_2', (manual_load_2, schema) =>
      manual_load_2 ? schema.required() : schema.optional(),
    ),
    manual_load_3: string()
      .when('manual_source_3', (manual_source_3, schema) =>
        manual_source_3 ? schema.required() : schema.optional(),
      )
      .test(testIsFormulaParsable),
    manual_source_3: string().when('manual_load_3', (manual_load_3, schema) =>
      manual_load_3 ? schema.required() : schema.optional(),
    ),
  },
  [
    ['manual_load_1', 'manual_source_1'],
    ['manual_source_1', 'manual_load_1'],
    ['manual_load_2', 'manual_source_2'],
    ['manual_source_2', 'manual_load_2'],
    ['manual_load_3', 'manual_source_3'],
    ['manual_source_3', 'manual_load_3'],
  ],
)

export const anchorDataGridSchema = object({
  rows: array().of(anchorRow),
})
