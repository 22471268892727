import { ReactElement } from 'react'
import { Close, SaveOutlined, SyncProblem } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material'
import {
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro'

interface Props {
  onClose: () => void
  onSave: () => void
  isDirty?: boolean
  isValid?: boolean
}

const AnchorDataGridToolbar = ({
  onClose,
  onSave,
  isDirty,
  isValid = true,
}: Props): ReactElement => {
  return (
    <GridToolbarContainer>
      <Stack direction="row" justifyContent="space-between" display="flex" flex={1} pl={1}>
        <Box>
          <GridToolbarQuickFilter />
          <GridToolbarFilterButton slotProps={{ button: { size: 'small' } }} />
          <GridToolbarColumnsButton
            slotProps={{
              tooltip: {
                slotProps: {
                  popper: {
                    popperOptions: {
                      placement: 'top',
                    },
                  },
                },
              },
            }}
          />
          <GridToolbarExport slotProps={{ button: { size: 'small' } }} />
          <Button
            size="small"
            onClick={onSave}
            variant="text"
            startIcon={<SaveOutlined />}
            data-cy="save-anchor-data-btn"
          >
            Speichern
          </Button>
        </Box>

        <Stack direction="row" spacing={1} alignItems="center">
          {isDirty && (
            <Tooltip
              title={
                isValid
                  ? 'Ungespeicherte Änderungen'
                  : 'Ungespeicherte Änderungen (Fehler in Eingabe)'
              }
            >
              <SyncProblem
                fontSize="small"
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                sx={theme => ({
                  color: isValid ? theme.palette.grey[600] : theme.palette.error.main,
                })}
                data-cy="unsynced-icon"
              />
            </Tooltip>
          )}
          <IconButton onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
    </GridToolbarContainer>
  )
}

export default AnchorDataGridToolbar
