import { ReactElement, useState, useMemo } from 'react'
import { isFunction } from 'lodash-es'
import CopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'
import StorageIcon from '@mui/icons-material/Storage'
import { TableCell, TableRow, Checkbox, IconButton, Tooltip, Typography } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles/'
import { AlertDialog, IconButtonProgress } from '@ui/feedback'
import { Box } from '@ui/structure'
import AssemblyPlot from '../AssemblyPlot'
import { calculateCumulativeWeight, generatePlottingLayers } from '../utils'

interface Props {
  assembly: Assembly
  assignedElements: string[]
  onDelete: () => void
  onDuplicate: () => void
  onEdit: () => void
  onSelect?: () => void
  onStore?: () => void
  isStandard?: boolean
  onDownload: () => Promise<void>
  'data-cy'?: string
}

const AssemblyListItem = ({
  assembly,
  assignedElements,
  onDelete,
  onDownload,
  onEdit,
  onSelect,
  onDuplicate,
  onStore,
  isStandard = false,
  'data-cy': dataCy,
}: Props): ReactElement => {
  const { name, guid, short_name } = assembly
  const isProjectAssembly = isFunction(onSelect)
  const [isDownloading, setIsDownloading] = useState(false)

  const defaultSX: SxProps<Theme> = ({ palette, spacing }: Theme) => ({
    '&:last-child td, &:last-child th': { border: 0 },
    '& td': {
      padding: spacing(1),
    },
    '&:hover': {
      background: palette.grey[100],
    },
    cursor: 'pointer',
    color: {
      background: palette.background.default,
    },
  })

  const cumulativeWeight = useMemo(() => {
    return calculateCumulativeWeight(
      generatePlottingLayers(
        assembly.layers,
        assembly.usage_type === 'Outer' || assembly.usage_type === 'Inner',
      ),
    )
  }, [assembly.layers, assembly.usage_type])

  return (
    <>
      <TableRow key={guid} sx={defaultSX}>
        {isProjectAssembly && (
          <TableCell component="th" scope="row">
            <Checkbox checked={isStandard} onChange={onSelect} data-cy={`${dataCy}-check`} />
          </TableCell>
        )}
        <TableCell>
          <AssemblyPlot
            assemblyType={assembly.usage_type}
            layers={assembly.layers}
            hasBorder={false}
            outsideWidth={100}
          />
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{short_name}</TableCell>
        {isProjectAssembly && (
          <TableCell align="center">
            <Tooltip
              title={<Typography fontSize={'small'}>{assignedElements.join('\n')}</Typography>}
              disableHoverListener={assignedElements.length === 0}
            >
              <Typography>{assignedElements.length}</Typography>
            </Tooltip>
          </TableCell>
        )}
        <TableCell align="center">
          <Typography data-cy={`${dataCy}-weight`}>{cumulativeWeight.toFixed(3)}</Typography>
        </TableCell>
        <TableCell align="right">
          <Box display="flex" justifyContent="flex-end">
            <IconButtonProgress
              isLoading={isDownloading}
              onClick={async () => {
                setIsDownloading(true)
                try {
                  await onDownload()
                } finally {
                  setIsDownloading(false)
                }
              }}
            >
              <DownloadIcon fontSize="small" />
            </IconButtonProgress>
            <AlertDialog
              title="Aufbauten verändern?"
              text={
                isProjectAssembly
                  ? 'Änderungen an diesem Aufbau werden nur innerhalb des Projektes angewandt und wirken sich nicht auf andere Projekte aus.'
                  : 'Die Änderung am Aufbau wird nicht in bestehende Projekte übernommen. Die Aufbauten müssen <b>jeweils</b> in den entsprechenden Projekten im Aufbauten Manager geändert werden.'
              }
              onConfirm={onEdit}
            >
              <IconButton data-cy={`${dataCy}-btn-edit`}>
                <EditIcon fontSize="small" />
              </IconButton>
            </AlertDialog>
            {isFunction(onStore) ? (
              <IconButton onClick={onStore}>
                <StorageIcon fontSize="small" />
              </IconButton>
            ) : (
              <></>
            )}
            <IconButton onClick={onDuplicate}>
              <CopyIcon fontSize="small" />
            </IconButton>
            <AlertDialog
              title="Aufbauten löschen?"
              text="Sind Sie sicher, dass Sie diesen Aufbau löschen Wollen?"
              onConfirm={async () => onDelete()}
            >
              <IconButton data-cy={`${dataCy}-delete`}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </AlertDialog>
          </Box>
        </TableCell>
      </TableRow>
    </>
  )
}

export default AssemblyListItem
