import { HyperFormula } from 'hyperformula'

export const getLoadsOrFunctionsForRow = (
  dataRow: AnchorInterventionsTableRowData,
): Partial<TableData> => {
  const manualLoadsInRow = [...Array(3)].map((_, index): Partial<TableData> => {
    const loadKey = 'manual_load_'.concat((index + 1).toString()) as keyof typeof dataRow
    const sourceKey = 'manual_source_'.concat((index + 1).toString()) as keyof typeof dataRow

    const manualLoad = dataRow.manual_loads[index]

    if (!manualLoad)
      return {
        [loadKey]: '',
        [sourceKey]: '',
      }

    if (manualLoad.load_formula) {
      return {
        [loadKey]: manualLoad.load_formula,
        [sourceKey]: manualLoad.source,
      }
    }

    return {
      [loadKey]: (manualLoad.load / 1000).toString(),
      [sourceKey]: manualLoad.source,
    }
  })

  const reducedManualLoadsInRow: Partial<TableData> = manualLoadsInRow.reduce(
    (collector, element) => {
      collector = { ...collector, ...element }
      return collector
    },
  )

  return reducedManualLoadsInRow
}

export const getValidatedLoadOrError = (
  dataRow: TableData,
  index: number,
): ManualLoad | null | Error => {
  // Returns an error if the load cells fail validation
  const loadKey = 'manual_load_'.concat(index.toString()) as keyof typeof dataRow
  const sourceKey = 'manual_source_'.concat(index.toString()) as keyof typeof dataRow

  const load = dataRow[loadKey] as string | undefined
  const source = dataRow[sourceKey] as string | undefined

  if (load && source) {
    if (load.startsWith('=')) {
      // TODO: add correct license key here
      const interimHFTable = HyperFormula.buildFromArray([[load]], { licenseKey: 'gpl-v3' })
      const calculatedValue = interimHFTable.getCellValue({ col: 0, row: 0, sheet: 0 }) as number
      return {
        load: calculatedValue * 1000,
        load_formula: load,
        source: source,
      }
    } else {
      return {
        load: Number(load) * 1000,
        load_formula: '',
        source: source,
      }
    }
  }

  if (load || source) {
    return new Error('Last ' + index.toString())
  }

  return null
}

export const getValidatedRowOrError = (
  dataRow: TableData,
  rowIndex: number,
): ManualLoadOnSupport | Error => {
  const loadsOnSupport: (ManualLoad | Error)[] = [...Array(3)]
    .map((_, index) => getValidatedLoadOrError(dataRow, index + 1))
    .filter(manualLoad => manualLoad) as (ManualLoad | Error)[]

  const errors = loadsOnSupport.filter(loadOnSupport => loadOnSupport instanceof Error) as Error[]
  if (errors.length > 0) {
    return new Error(
      'Missing data at row '.concat(
        (rowIndex + 1).toString(),
        ': ',
        errors.map(error => error.message).join('; '),
      ),
    )
  }

  return {
    support_id: dataRow.support_guid,
    manual_loads: loadsOnSupport,
    comment: dataRow.comment ? dataRow.comment : '',
  } as ManualLoadOnSupport
}
