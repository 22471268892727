import { last, toNumber } from 'lodash-es'
import { Typography } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro'
import { formulaToNumber } from '@utils'

export const manualSourceSetter =
  (key: string) => (val: string, row: AnchorInterventionsTableRowData) => {
    if (val === 'empty') return { ...row, [key]: undefined }

    const valAsNumber = toNumber(val)
    const manual_source = isNaN(valAsNumber) ? val : valAsNumber * 1000
    return { ...row, [key]: manual_source }
  }

export const autoRowHeight: (params: GridRowHeightParams) => GridRowHeightReturnValue = () => 'auto'

export const manualLoadSetter =
  (field: `manual_load_${1 | 2 | 3}`) => (val: string, row: AnchorInterventionsTableRowData) => ({
    ...row,
    [field]: val === '' ? undefined : typeof val === 'string' ? val.trim() : val,
  })

export const createManualLoadField = (
  fieldName: `manual_load_${1 | 2 | 3}`,
): GridColDef<AnchorInterventionsTableRowData> => ({
  field: fieldName,
  headerName: `Last [kN] - ${last(fieldName)}`,
  renderHeader: () => <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>Last</Typography>,
  headerAlign: 'center',
  editable: true,
  align: 'right',
  width: 75,
  renderCell: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: GridRenderCellParams<AnchorInterventionsTableRowData, any, any, GridTreeNodeWithRender>,
  ) => {
    try {
      if (params.cellMode === 'view') {
        return formulaToNumber(params.value).toFixed(2)
      }
    } catch {
      // Do nothing
    }
    return params.value
  },
  valueSetter: manualLoadSetter(fieldName),
})
