import { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { filter, find, maxBy, reject } from 'lodash-es'
import {
  TableCell,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  CircularProgress,
} from '@mui/material'
import { UtilizationC90TextIcon, UtilizationTextIcon } from '@ui/icons/misc'
import { getCalcChecksForElement } from '@queries'
import { CrossSectionFormDataType } from 'src/components/pages/Editor/components/SingleElementCSForm/schema'
import { BundleItem } from '../../LintelCompressionSettingsForm/formFields'
import { renderUtilization } from '../../LintelCompressionSettingsForm/utils'

export const formBundlesToUpdatedCheckSettings = (
  formCheckSettings: SettingsOnMember,
  formBundles: BundleItem[],
) => {
  if (
    formCheckSettings.setting_type === 'steel-column' ||
    formCheckSettings.setting_type === 'other'
  ) {
    return formCheckSettings
  }
  const updatedSupportConfigs = formCheckSettings.support_configs.map(config => {
    const bundle = formBundles.find(
      bundle => bundle.supportConfig.relative_position === config.relative_position,
    )
    return bundle ? bundle.supportConfig : config
  })
  return {
    ...formCheckSettings,
    support_configs: updatedSupportConfigs,
  }
}

const UtilizationPreview = ({ elementGuid }: { elementGuid: string }) => {
  const { projectId } = useParams()

  // unpack form data
  const { setValue } = useFormContext()
  const formBundles: BundleItem[] = useWatch({ name: 'formBundles' })
  const crossSectionFormData: CrossSectionFormDataType = useWatch({ name: 'crossSection' })
  const checkSettings: SettingsOnMember = useWatch({ name: 'checkSettings' })

  // derive values from form state
  const newLintelCS = useMemo(
    () =>
      ({
        material: crossSectionFormData.material,
        shape:
          crossSectionFormData.materialType === 'steelMaterial'
            ? crossSectionFormData.steelShape
            : crossSectionFormData.shape,
        usage_class: crossSectionFormData.usage_class,
      } as CrossSection),
    [
      crossSectionFormData.material,
      crossSectionFormData.materialType,
      crossSectionFormData.shape,
      crossSectionFormData.steelShape,
      crossSectionFormData.usage_class,
    ],
  )

  const updatedSettingsOnElement = useMemo(
    () => formBundlesToUpdatedCheckSettings(checkSettings as SettingsOnMember, formBundles),
    [checkSettings, formBundles],
  )

  const queryParams = {
    elementGuid: elementGuid,
    crossSection: newLintelCS,
    updatedSettingsOnElement,
  }
  const { data: computedChecksData, isLoading: isComputingChecks } = useQuery(
    ['calc-element-checks', queryParams],
    () => {
      return getCalcChecksForElement.request(
        projectId as string,
        queryParams.elementGuid,
        queryParams.updatedSettingsOnElement,
        queryParams.crossSection,
      )
    },
  )

  useEffect(() => {
    if (!computedChecksData) return

    const elementPositionChecks = computedChecksData.checks
    setValue('checks', elementPositionChecks)
    const elementChecksOnlySupportCompression = filter(elementPositionChecks, check =>
      ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
    ) as SupportCompressionStructuralCheck[] | SteelCompressionCheck[]
    formBundles.forEach((bundle, idx) => {
      const correspondingCheck = find(elementChecksOnlySupportCompression, {
        relative_position: bundle.check.relative_position,
      })
      if (correspondingCheck) {
        setValue(`formBundles.${idx}.check`, correspondingCheck)
      }
    })
  }, [computedChecksData, formBundles, setValue])

  const elementPositionChecks = computedChecksData && computedChecksData.checks

  const elementChecksWithoutSupportCompression = useMemo(
    () =>
      reject(elementPositionChecks, check =>
        ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
      ),
    [elementPositionChecks],
  )

  const elementChecksOnlySupportCompression = useMemo(
    () =>
      filter(elementPositionChecks, check =>
        ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
      ),
    [elementPositionChecks],
  )

  const maxUtilOnlySupportCompression = useMemo(
    () =>
      maxBy(elementChecksOnlySupportCompression, check => check.max_utilization)?.max_utilization,
    [elementChecksOnlySupportCompression],
  )

  const maxUtilWithoutSupportCompression = useMemo(
    () =>
      maxBy(elementChecksWithoutSupportCompression, check => check.max_utilization)
        ?.max_utilization,
    [elementChecksWithoutSupportCompression],
  )

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100px' }}>
      <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <UtilizationTextIcon />
            </TableCell>
            <TableCell align="center">
              <UtilizationC90TextIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">
              {isComputingChecks ? (
                <CircularProgress size={16} />
              ) : (
                renderUtilization(maxUtilWithoutSupportCompression)
              )}
            </TableCell>
            <TableCell align="center">
              {isComputingChecks ? (
                <CircularProgress size={16} />
              ) : (
                renderUtilization(maxUtilOnlySupportCompression)
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { UtilizationPreview }
