import { ReactElement, useMemo, useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { cloneDeep, find, merge } from 'lodash-es'
import { closeSnackbar, useSnackbar } from 'notistack'
import { Box, CircularProgress, Popover } from '@mui/material'
import { Form } from '@ui/forms'
import { useResultsStore } from '@editorStores'
import { getElementChecksBundle, getMemberCheckSettings, postCalcPositionChecks } from '@queries'
import { saveSingleMemberCheckSettings } from '@mutations'
import ShearCheckSettingsFormFields from '../../../../../MemberChecksTable/components/ShearCheckRow/components/ShearCheckSettingsFormFields'

interface Props {
  anchorEl?: HTMLElement
  onClose: () => void
  memberGuid: string
}

const ShearCheckSettingsPopupForm = ({ memberGuid, anchorEl, onClose }: Props): ReactElement => {
  const formRef = useRef<HTMLFormElement | null>(null)
  const { projectId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const memberCheckSettings = useResultsStore(state => state.memberCheckSettings)
  const setSingleMemberCheckSetting = useResultsStore(state => state.setSingleMemberCheckSetting)

  const settings: TimberCheckSettings | undefined = useMemo(
    () =>
      find(
        memberCheckSettings,
        setting => setting.member_guid === memberGuid,
      ) as TimberCheckSettings,
    [memberCheckSettings, memberGuid],
  )

  const mutationKey = useMemo(
    () => saveSingleMemberCheckSettings.getKey(projectId as string, memberGuid),
    [projectId, memberGuid],
  )

  const { mutate, isLoading } = useMutation(
    (data: SettingsOnMember) => {
      const mergedConfig = merge(cloneDeep(settings), data)

      return saveSingleMemberCheckSettings.request(projectId as string, mergedConfig)
    },
    {
      mutationKey: mutationKey,
      onSuccess: async data => {
        setSingleMemberCheckSetting(data)
        enqueueSnackbar('Einstellungen erfolgreich gespeichert', {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 3000,
        })
        queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
        const elementsToCompute = [memberGuid]
        const infoMessage = `[Backend] Aktualisiere Nachweise für ${memberGuid} ...`
        enqueueSnackbar(infoMessage, { variant: 'info', key: infoMessage, preventDuplicate: true })
        postCalcPositionChecks.request(projectId as string, elementsToCompute).then(() => {
          closeSnackbar(infoMessage)
          enqueueSnackbar(`[Backend] Nachweise für ${memberGuid} erfolgreich berechnet`, {
            variant: 'success',
            key: 'update-checks-for-member-backend',
            preventDuplicate: true,
            autoHideDuration: 3000,
          })
          queryClient.invalidateQueries(getElementChecksBundle.getKey(projectId))
        })
      },
      onError: () => {
        enqueueSnackbar('Einstellungen konnten nicht gespeichert werden', { variant: 'error' })
        // Reset to the backend state when saving fails, to ensure local-state is in sync with backend
        queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
      },
    },
  )

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => {
        if (isLoading) return

        onClose()

        formRef.current?.requestSubmit()
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box p={2}>
        <Form
          ref={formRef}
          onSubmit={(state, _, isDirty) => {
            if (!isDirty) return

            mutate(state)
          }}
          defaultValues={settings}
          enableReinitialize
          data-cy="shear-check-popup-form"
        >
          <Box maxWidth={400}>
            <ShearCheckSettingsFormFields checkboxDisabled={isLoading} showHintAsTooltip />
          </Box>
        </Form>
        {isLoading && (
          <Box display="flex" flex={1} justifyContent="center" p={2}>
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>
    </Popover>
  )
}

export default ShearCheckSettingsPopupForm
