import {
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { loadCaseLabels } from '@editorUtils'
import {
  useCornerConnectionAdditionalLoadProposalState,
  useResultsQueryParams,
} from '@resultsHooks'
import produce from 'immer'
import {
  findIndex,
  isArray,
  isNaN,
  isUndefined,
  last,
  reduce,
  sortBy,
  toNumber,
  isNull,
  round,
} from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Warning } from '@mui/icons-material'
import AnchorIcon from '@mui/icons-material/Anchor'
import {
  Stack,
  Card,
  CardContent,
  Popover,
  Typography,
  Alert,
  CardActions,
  Button,
  Tooltip,
  Select,
  MenuItem,
} from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  GridProSlotsComponent,
  GridRenderEditCellParams,
  GridRowSelectionModel,
  GridRowsProp,
  GridTreeNode,
  GridTreeNodeWithRender,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { UtilizationTextIcon } from '@ui/icons/misc'
import { useElementLabel } from '@editorHooks'
import { formulaToNumber } from '@utils'
import { useControlStore } from 'src/components/pages/Editor/stores/controlStore'
import {
  getCellClassName,
  gridColumnGroupingModel,
  initialState,
  loadSources,
  slotProps,
} from '../../constants'
import AnchorDataGridToolbar from '../AnchorDataGridToolbar'
import StyledDataGrid from '../StyledDataGrid'
import { createManualLoadField } from './misc'

const snackbarWarningKey = 'fix-or-reset-anchor-grid-snackbar'

interface Ref {
  selectCell: (anchorGuid: string, field: string) => void
  insertCell: (anchorGuid: string, field: string, val: string) => void
  extend: (anchorGuid: string, field: string, val: string) => Promise<void>
}

export type { Ref as AnchorDataGrid2Ref }

interface Props {
  disallowSelection?: boolean
  onSave: () => void
  disableAllExcept?: { anchorGuid: string; fields: string[] }
}

const AnchorDataGridTable = forwardRef<Ref, Props>(function AnchorDataGrid2(
  { disallowSelection, onSave: onClose, disableAllExcept }: Props,
  ref,
): ReactElement {
  const getLabel = useElementLabel()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const setIsAnchorCalculationMode = useControlStore(state => state.setIsAnchorCalculationMode)

  const { selectAnchor } = useCornerConnectionAdditionalLoadProposalState()

  const { watch, setValue, trigger, formState } = useFormContext()

  const {
    params: { selectedConnector },
    actions: { selectConnector },
  } = useResultsQueryParams()

  const rows: GridRowsProp<AnchorInterventionsTableRowData> = watch('rows')

  const gridApiRef = useGridApiRef()

  useImperativeHandle(
    ref,
    () => ({
      selectCell: (anchorGuid: string, field: string) =>
        gridApiRef.current?.setCellFocus(anchorGuid, field),
      insertCell: async (anchorGuid: string, field: string, value: string) => {
        await gridApiRef.current?.startCellEditMode({
          id: anchorGuid,
          field,
        })
        await gridApiRef.current?.setEditCellValue({
          id: anchorGuid,
          field,
          value: value.toString(),
        })

        await gridApiRef.current?.stopCellEditMode({ id: anchorGuid, field: field })
      },
      extend: async (anchorGuid: string, field: string, value: string) => {
        const cellValue = await gridApiRef.current?.getCellValue(anchorGuid, field)

        await gridApiRef.current?.startCellEditMode({
          id: anchorGuid,
          field,
        })
        await gridApiRef.current?.setEditCellValue({
          id: anchorGuid,
          field,
          value: cellValue === '' ? value : `${cellValue}; ${value}`,
        })

        await gridApiRef.current?.stopCellEditMode({ id: anchorGuid, field: field })
      },
    }),
    [gridApiRef],
  )

  const [anchorEl, setAnchorEl] = useState<{
    element: HTMLDivElement | null
    supportGuid: string
    field: `manual_load_${1 | 2 | 3}`
  } | null>(null)

  // MEMOs

  const sourceGuidToAnchors = useMemo(
    () =>
      reduce(
        rows,
        (col, el) => ({
          ...col,
          [el.source_guid]: isArray(col[el.source_guid])
            ? sortBy([...col[el.source_guid], el.support_guid])
            : [el.support_guid],
        }),
        {} as Record<string, string[]>,
      ),
    [rows],
  )

  const anchorNameToNumber = useMemo(
    () =>
      reduce(
        rows,
        (col, el) => ({
          ...col,
          [el.support_guid]: (findIndex(
            sourceGuidToAnchors[el.source_guid],
            name => name === el.support_guid,
          ) + 1) as number,
        }),
        {} as Record<string, number>,
      ),
    [rows, sourceGuidToAnchors],
  )

  const renderManualSource = useCallback((field: string) => {
    const ManualSourceComp = (
      params: GridRenderEditCellParams<
        AnchorInterventionsTableRowData,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        GridTreeNodeWithRender
      >,
    ) => (
      <Select
        value={params.value}
        onChange={event => {
          params.api.setEditCellValue({
            id: params.id,
            value: event.target.value === loadSources.empty ? undefined : event.target.value,
            field: field,
          })
          params.api.stopCellEditMode({
            id: params.id,
            field: field,
          })

          if (
            params.field.startsWith('manual_source_') &&
            event.target.value === loadSources.wallCorner
          ) {
            const element = params.api.getCellElement(params.id, params.field)

            setAnchorEl({
              element: element,
              supportGuid: params.row.support_guid,
              field: `manual_load_${last(params.field) as '1' | '2' | '3'}`,
            })
          }
        }}
        autoFocus
        open
        onClose={() =>
          params.api.stopCellEditMode({
            id: params.id,
            field: field,
          })
        }
        style={{ width: '100%', border: 'none' }}
        data-cy="manual-source-select"
      >
        {Object.keys(loadSources).map(key => (
          <MenuItem key={key} value={loadSources[key as LoadSourceType]}>
            {loadSources[key as LoadSourceType]}
          </MenuItem>
        ))}
      </Select>
    )

    return ManualSourceComp
  }, [])

  // COLUMN definition

  const columns: GridColDef<AnchorInterventionsTableRowData>[] = useMemo(
    () => [
      {
        field: 'element_guid',
        headerName: 'Element',
        valueGetter: (val: string) => getLabel(val),
        width: 70,
      },
      {
        field: 'segment_guid',
        headerName: 'Segment',
        renderHeader: () => (
          <Tooltip title="Segment">
            <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>Seg</Typography>
          </Tooltip>
        ),
        headerAlign: 'center',
        valueGetter: (val: string | undefined | null, row) => {
          if (isUndefined(val) || isNull(val)) return val

          // in order to make the column smaller we remove the duplicate
          // information (the element label) that is already shown in the element
          // column
          const label = getLabel(val)
          const elementLabel = getLabel(row.element_guid)
          if (label.startsWith(elementLabel) && label !== elementLabel) {
            const shortLabel = label.substring(elementLabel.length)
            return shortLabel.startsWith('.') ? shortLabel.substring(1) : shortLabel
          }

          return label
        },
        width: 45,
        minWidth: 45,
      },
      {
        field: 'support_guid',
        headerName: 'Anker',
        renderHeader: () => (
          <Tooltip title="Anker">
            <AnchorIcon fontSize="inherit" />
          </Tooltip>
        ),
        headerAlign: 'center',
        valueGetter: (val: string) => anchorNameToNumber[val],
        width: 35,
        minWidth: 25,
        align: 'center',
      },
      {
        field: 'original_design_force',
        headerName: 'Fd [kN]',
        renderHeader: () => (
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
            F<sub>d</sub>
          </Typography>
        ),
        headerAlign: 'center',
        valueGetter: (val: number) => (val / 1000).toFixed(2),
        type: 'number',
        width: 65,
      },
      {
        field: 'original_design_force_source',
        headerName: 'maßgebender Lastfall',
        renderHeader: () => {
          return (
            <Tooltip title="maßgebender Lastfall">
              <div>
                <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>Lastfall</Typography>
              </div>
            </Tooltip>
          )
        },
        valueGetter: (val: LoadCaseTypes, row) =>
          loadCaseLabels[row.original_design_force_source_category]?.[val]?.short || 'n/a',
        width: 65,
      },
      createManualLoadField('manual_load_1'),
      {
        field: 'manual_source_1',
        headerName: 'Herkunft - 1',
        renderHeader: () => (
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>Herkunft</Typography>
        ),
        headerAlign: 'center',
        align: 'left',
        width: 110,
        editable: true,
        renderEditCell: renderManualSource('manual_source_1'),
      },
      createManualLoadField('manual_load_2'),
      {
        field: 'manual_source_2',
        headerName: 'Herkunft - 2',
        renderHeader: () => (
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>Herkunft</Typography>
        ),
        headerAlign: 'center',
        width: 110,
        editable: true,
        renderEditCell: renderManualSource('manual_source_2'),
      },
      createManualLoadField('manual_load_3'),
      {
        field: 'manual_source_3',
        headerName: 'Herkunft - 3',
        renderHeader: () => (
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>Herkunft</Typography>
        ),
        headerAlign: 'center',
        width: 110,
        editable: true,
        renderEditCell: renderManualSource('manual_source_3'),
      },
      {
        headerName: 'Neu Fd[kN]',
        field: 'load_sum',
        renderHeader: () => (
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
            F<sub>d,neu</sub>
          </Typography>
        ),
        width: 65,
        valueGetter: (val, row) => {
          const originalDesignForce = toNumber(row.original_design_force) / 1000

          try {
            const load1 = !isUndefined(row.manual_load_1) ? formulaToNumber(row.manual_load_1) : 0
            const load2 = !isUndefined(row.manual_load_2) ? formulaToNumber(row.manual_load_2) : 0
            const load3 = !isUndefined(row.manual_load_3) ? formulaToNumber(row.manual_load_3) : 0

            return (
              originalDesignForce +
              (isNaN(load1) ? 0 : round(load1, 2)) +
              (isNaN(load2) ? 0 : round(load2, 2)) +
              (isNaN(load3) ? 0 : round(load3, 2))
            ).toFixed(2)
          } catch (e) {
            return 'Fehler'
          }
        },
        type: 'number',
      },
      {
        field: 'anchor_utilization',
        headerName: 'Ausnutzung',
        headerAlign: 'center',
        renderHeader: () => (
          <Tooltip title="Ausnutzung">
            <UtilizationTextIcon />
          </Tooltip>
        ),
        valueGetter: (val: number) => {
          const num = toNumber(val)
          if (isNaN(num)) return undefined

          return num.toFixed(2)
        },
        type: 'number',
        width: 60,
      },
      {
        field: 'anchor_name',
        headerName: 'Zuganker',
        flex: 1,
        width: 280,
      },
      {
        field: 'comment',
        headerName: 'Hinweis',
        editable: true,
        minWidth: 350,
        renderCell: params => (
          <Tooltip title={params.value} placement="left">
            {params.formattedValue}
          </Tooltip>
        ),
        flex: 1,
      },
    ],
    [anchorNameToNumber, getLabel, renderManualSource],
  )

  // Select the connector which is selected in the scene also in the table
  useEffect(() => {
    const selection = gridApiRef.current?.getSelectedRows()

    if (selectedConnector && !selection?.has(selectedConnector)) {
      gridApiRef.current?.selectRow(selectedConnector, true, true)
      const index = rows.findIndex(row => row.support_guid === selectedConnector)
      try {
        gridApiRef.current?.scrollToIndexes({
          rowIndex: index,
        })
      } catch (e) {
        if (
          e instanceof TypeError &&
          e.message === "Cannot read properties of null (reading 'scrollTop')"
        ) {
          // do nothing
        } else throw e
      }
    }
  }, [gridApiRef, rows, selectedConnector])

  const processRowUpdate = useCallback(
    (newRow: GridValidRowModel) => {
      const updatedArray = produce(rows, draft => {
        const index = draft.findIndex(item => item.support_guid === newRow.support_guid)
        if (index !== -1) {
          // @ts-expect-error
          draft[index] = newRow
        }
      })

      setValue('rows', updatedArray, { shouldDirty: true })
      return newRow
    },
    [rows, setValue],
  )

  const onRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      if (disallowSelection) return

      if (selectionModel.length === 1) {
        selectConnector(selectionModel[0] as string)
      }
    },
    [selectConnector, disallowSelection],
  )

  const slots: Partial<GridProSlotsComponent> = useMemo(
    () => ({
      toolbar: () => (
        <AnchorDataGridToolbar
          isDirty={formState.isDirty}
          onClose={async () => {
            const isValid = await trigger()
            if (!isValid) {
              enqueueSnackbar('Es sind noch Fehler in der Tabelle vorhanden', {
                key: snackbarWarningKey,
                persist: false,
                variant: 'info',
              })
              return
            }
            setIsAnchorCalculationMode(false)
            onClose()
            closeSnackbar(snackbarWarningKey)
          }}
          onSave={async () => {
            const isValid = await trigger()
            if (!isValid) {
              enqueueSnackbar('Es sind noch Fehler in der Tabelle vorhanden', {
                key: snackbarWarningKey,
                persist: false,
                variant: 'info',
              })
              return
            }

            onClose()
          }}
        />
      ),
    }),
    [
      closeSnackbar,
      enqueueSnackbar,
      formState.isDirty,
      onClose,
      setIsAnchorCalculationMode,
      trigger,
    ],
  )

  const isCellEditable = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (params: GridCellParams<any, GridValidRowModel, GridValidRowModel, GridTreeNode>) => {
      if (!disallowSelection) return true

      if (isUndefined(disableAllExcept)) return true

      if (
        params.id === disableAllExcept.anchorGuid &&
        disableAllExcept.fields.includes(params.field)
      ) {
        return true
      }

      return false
    },
    [disableAllExcept, disallowSelection],
  )

  if (isUndefined(rows)) return <></>

  return (
    <>
      <StyledDataGrid
        disableSelectionOnClick={disallowSelection}
        // don't know why but after converting DataGrid to a styled component
        // there are typing issues for ref and columns, hence we use
        // ts-expect-error for now
        // @ts-expect-error
        apiRef={gridApiRef}
        rows={rows}
        // @ts-expect-error
        columns={columns}
        density="compact"
        initialState={initialState}
        editMode="cell"
        rowHeight={35}
        columnHeaderHeight={40}
        showCellVerticalBorder
        onRowSelectionModelChange={onRowSelectionModelChange}
        getCellClassName={getCellClassName}
        processRowUpdate={processRowUpdate}
        showColumnVerticalBorder
        columnGroupingModel={gridColumnGroupingModel}
        slotProps={slotProps}
        slots={slots}
        hideFooter
        // autosizeOnMount
        // autosizeOptions={{ includeHeaders: false, expand: true }}
        // getRowHeight={autoRowHeight}
        disableRowSelectionOnClick={disallowSelection}
        isCellEditable={isCellEditable}
        data-cy="anchor-table"
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl?.element}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">Eingabehilfe für zusätzliche Lasten.</Typography>
              <Alert icon={<Warning fontSize="inherit" />} severity="warning">
                In dieser Zeit wird die Tabelle für Eingaben gesperrt.{' '}
              </Alert>
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                if (!anchorEl) return
                selectAnchor(anchorEl.supportGuid, 0, 0, anchorEl.field)
                setAnchorEl(null)
              }}
              variant="contained"
            >
              Wand auswählen
            </Button>
            <Button onClick={() => setAnchorEl(null)}>Abbrechen</Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  )
})

export default AnchorDataGridTable
