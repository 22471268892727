import { ReactElement } from 'react'
import { materialTypeOptions } from '@domainConstants'
import { materialTypeFromCS, renameSWxxToCxxIfApplicable } from '@domainUtils'
import { find } from 'lodash-es'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { Label } from '@ui/forms'
import { usageClassToLabel } from 'src/constants/elements'

interface Props {
  crossSection?: CrossSection
}

const CrossSectionInfo = ({ crossSection }: Props): ReactElement => {
  return (
    <Stack
      p={1}
      border={1}
      borderColor="grey.200"
      borderRadius={1}
      spacing={1}
      direction="column"
      data-cy="crosssection-info"
    >
      <Stack direction="row">
        <Stack direction="column" display="flex" flex={1}>
          <Label>Materialtyp</Label>
          <Typography data-cy="crosssection-info-material-type">
            {crossSection
              ? find(
                  materialTypeOptions,
                  option => option.value === materialTypeFromCS(crossSection),
                )?.label || 'Undefiniert'
              : 'Undefiniert'}
          </Typography>
        </Stack>

        <Stack direction="column" display="flex" flex={1}>
          <Label>Material</Label>
          <Typography data-cy="crosssection-info-material">
            {crossSection?.material.identifier
              ? renameSWxxToCxxIfApplicable(crossSection?.material.identifier)
              : 'Undefiniert'}
          </Typography>
        </Stack>
      </Stack>

      <Divider />

      <Stack direction="row">
        <Stack direction="column" display="flex" flex={1}>
          <Label>QS-Breite</Label>
          <Typography data-cy="crosssection-info-width">
            {crossSection?.shape.width || 'Undefiniert'}
          </Typography>
        </Stack>

        <Stack direction="column" display="flex" flex={1}>
          <Label>QS-Höhe</Label>
          <Typography data-cy="crosssection-info-height">
            {crossSection?.shape.height || 'Undefiniert'}
          </Typography>
        </Stack>
      </Stack>

      <Divider />

      <Box>
        <Label>Nutzungsklasse</Label>
        <Typography>
          {crossSection
            ? usageClassToLabel[crossSection?.usage_class] || 'Undefiniert'
            : 'Undefiniert'}
        </Typography>
      </Box>
    </Stack>
  )
}

export default CrossSectionInfo
