import { sum } from 'lodash-es'
import { meterToMillimeter } from '@utils'
import { kilogramToKilonewton } from 'src/utils/units'

const selectTimberFramePlot = (thickness: number, width: number) => {
  const thicknessInMeters = thickness / 1000

  if (thicknessInMeters >= width) return 'TimberFrameInsulationFull'
  if (thicknessInMeters < width && thicknessInMeters > 0) return 'TimberFrameInsulationPartly'
  else return 'TimberFrameNoInsulation'
}

const isNonSteelMaterial = (
  material: Material,
): material is LVLMemberMaterial | SoftwoodMemberMaterial | GlulamMemberMaterial => {
  return material.kind !== 'Steel'
}

const calculateTimberFrameWeight = (layer: TimberFrameLayer, isWallAssembly: boolean) => {
  const { standard_rip_cs } = layer

  if (!isNonSteelMaterial(standard_rip_cs.material)) {
    throw new Error('TimberFrameLayer cannot have a standard_rip_cs made from SteelMaterial')
  }

  const memberWeight = isWallAssembly
    ? standard_rip_cs.shape.width *
      standard_rip_cs.shape.height *
      (2 / 3 + 1 / layer.rip_step_size) *
      standard_rip_cs.material.density
    : standard_rip_cs.shape.width *
      standard_rip_cs.shape.height *
      (1 / layer.rip_step_size) *
      standard_rip_cs.material.density

  const layerWeight = layer.insulation ? layer.insulation.density * layer.insulation_thickness : 0

  const totalWeight = memberWeight + layerWeight
  return totalWeight
}

const toCLTLayer = ({ clt }: CLTAssemblyLayer): PlotLayer => ({
  layerType: 'CLT' + clt.layers.length + 'Layer',
  thickness: meterToMillimeter(calculateCumulativeThickness(clt.layers)),
  product: clt.unique_name,
  layerName: 'CLTLayer',
})

const toProductLayer = ({ product, thickness, color }: ProductLayer): PlotLayer => ({
  layerType: 'Formwork',
  product: product.name,
  thickness: meterToMillimeter(thickness),
  color: color,
  density: product.density,
  layerName: 'ProductLayer',
  weight: kilogramToKilonewton(product.density * thickness),
})

const toPlateLayer = ({ plate }: PlateLayer): PlotLayer => ({
  layerType: 'GypsumBoard',
  thickness: meterToMillimeter(plate.material.thickness),
  layerName: 'PlateLayer',
  product: plate.unique_name,
  density: plate.material.density,
  weight: kilogramToKilonewton(plate.material.density * plate.material.thickness),
})

const toTimberFrameLayer = (layer: TimberFrameLayer, isWallAssembly: boolean): PlotLayer => ({
  layerType: selectTimberFramePlot(layer.insulation_thickness, layer.standard_rip_cs.shape.width),
  thickness: meterToMillimeter(layer.standard_rip_cs.shape.height),
  layerName: 'TimberFrameLayer',
  density: layer.insulation ? layer.insulation.density : 0,
  product: layer.standard_rip_cs.unique_name,
  weight: kilogramToKilonewton(calculateTimberFrameWeight(layer, isWallAssembly)),
})

export const convertToPlotLayer = (layer: Layer, isWallAssembly: boolean): PlotLayer => {
  switch (layer.kind) {
    case 'clt-assembly-layer':
      return toCLTLayer(layer as CLTAssemblyLayer)
    case 'product-layer':
      return toProductLayer(layer as ProductLayer)
    case 'plate-layer':
      return toPlateLayer(layer as PlateLayer)
    case 'timber-frame-layer':
      return toTimberFrameLayer(layer as TimberFrameLayer, isWallAssembly)
  }
}

export const calculateCumulativeThickness = (layers: PlotLayer[] | CLTLayer[]) => {
  return sum(layers.map(layer => Number(layer.thickness)))
}

export const calculateCumulativeWeight = (layers: PlotLayer[]) => {
  return sum(layers.map(layer => Number(layer.weight) || 0))
}

export const generatePlottingLayers = (layers: Layer[], isWallAssembly: boolean): PlotLayer[] => {
  const convertedLayers = layers.map(layer => convertToPlotLayer(layer, isWallAssembly))

  return convertedLayers.filter(<T>(l: T): l is NonNullable<T> => l !== null)
}
